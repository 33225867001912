import Swal from "sweetalert2";
import Loader from '../../../assets/services/loader.svg';


const ContactingServer = () => {
    return Swal.fire({
        iconHtml: `<img src="${Loader}" />`,
        title: 'Contacting Server',
        text: 'Waiting for response.',
        showConfirmButton: false,
        customClass: {
            icon: 'swal-icon-loader',
            title: 'contacting-server-title',
            text: 'contacting-server-text swal-text',
            content:'swal-content',
        }
    });
}

export default ContactingServer;