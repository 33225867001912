import { Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import loginImage from "../../../assets/login.svg";
import Snackbar from "@mui/material/Snackbar";
import { useEffect, useState } from "react";
import { Alert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import DrawerAppBar from "../../../components/navbar";
import { HttpRequest } from "../../../components/HttpRequest";

const ForgotPassword = () => {
    const [open, setOpen] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [message, setMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [medium, setMedium] = useState("");
    const [codeSent, setCodeSent] = useState(false);

    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        var isValid = true;
        setOpen(false);
        setMessage("");

        if (medium === "") {
            setOpen(true);
            setMessage("Please select either Email or Phone Number.");
            isValid = false;
        }
        if (email === "" && medium == "email") {
            setOpen(true);
            setMessage("Please enter your email ");
            isValid = false;
        }
        if (phoneNumber === "" && medium == "phoneNo") {
            setOpen(true);
            setMessage("Please enter your Phone Number");
            isValid = false;
        }

        if (isValid) {
            const idNo = medium === "email" ? email : phoneNumber
            try {
                const requestParams = {
                    "idNo": idNo,
                    "medium": medium
                };
                const url = "reset-password";
                const response = await HttpRequest({ url, requestParams });
                console.log("my response", response);
                if (response.data.status === "00") {
                    setCodeSent(true);
                } else {
                    console.log("Fail 443");
                    setOpen(true);
                    setMessage("Failed to request new verification code!");
                }
            } catch (error) {
                console.log("Fail");
                setOpen(true);
                setMessage("Failed to request new verification code!")
            }
        }
    }
    useEffect(() => {
        if (codeSent) {
            const channel = medium === "email" ? "email" : "phone";
            setOpenSuccess(true);
            setSuccessMessage(`Your password has been reset successfully. Please check your ${ channel }`);
        }
    }, [codeSent]);

    const handleClose = (event, reason) => {
        setOpen(false);
        setOpenSuccess(false);
        setMessage("");
        setSuccessMessage("");
    };

    return (
        <>
            <DrawerAppBar />
            <Box component="main" sx={{ marginBottom: { xs: '10%', sm: '10%', md: '58%', lg: '5%' } }}>
                <Grid container={true} sx={{ marginTop: { xs: '40%', sm: '15%', md: '10%', lg: '5%' }, alignContent: 'center', width: '100%', height: '100%', textAlign: 'center' }} spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12} lg={6} sx={{ textAlign: 'center', alignContent: 'center' }}>
                        <Typography sx={{ marginLeft: { xs: '10%', sm: '10%', md: '18%', lg: '18%' }, objectFit: 'contain' }}>
                            <img src={loginImage} alt="" style={{ width: '70%' }} />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} sx={{ textAlign: 'center', alignContent: 'center' }}>
                        <Box sx={{ width: '100%', textAlign: 'center' }}>
                            <Typography variant="h4" sx={{ width: '100%', height: 'auto', padding: '0 20% 0 20%', whiteSpace: "pre-wrap", marginLeft: { xs: '5%', sm: '2%', md: '2%', lg: '5%' }, marginTop: { xs: '5%', sm: '3%', md: '3%', lg: '2%' }, marginBottom: { xs: '6%', sm: '5%', md: '3%', lg: '2%' }, fontFamily: 'Inter', fontSize: '22px', fontWeight: 600, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.59, letterSpacing: 'normal', color: '#707070', textAlign: 'center' }}>
                                Forgot PIN?
                            </Typography>
                            <Typography variant="h6" sx={{ width: { xs: '50%', sm: '50%', md: '30%', lg: '35%' }, height: 'auto', marginLeft: { xs: '28%', sm: '35%', md: '39%', lg: '35%' }, marginBottom: { xs: '7%', sm: '2%', md: '2%', lg: '5%' }, fontFamily: 'Inter', fontSize: '18px', fontWeight: 500, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.94, letterSpacing: 'normal', textAlign: 'center', color: '#9b9b9b' }}>
                                Please enter your email address or phone number
                            </Typography>
                            <form onSubmit={handleLogin} autoComplete="off">
                                <Grid container={true} sx={{ marginLeft: { xs: '10%', sm: '30%', md: '26%', lg: '29%' } }}>
                                    <FormControl sx={{ width:{xs: '80%', sm: '60%', md: '50%', lg: '50%'}, marginRight: '1%' }} color="grey">
                                        <InputLabel id="duration-label">Sent Code Via</InputLabel>
                                        <Select onChange={e => setMedium(e.target.value)} SelectDisplayProps={{ style: { textAlign: 'left' } }} labelId="duration-label" id="duration-select" placeholder="Enter Email or Phone No.." color="grey" label='Email or Phone No.' style={{ width: '100%' }}  >
                                            <MenuItem value="email">Email</MenuItem>
                                            <MenuItem value="phoneNo">Phone Number</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {medium === "email" &&
                                        <FormControl sx={{ width: { xs: '80%', sm: '60%', md: '60%', lg: '55%' }, marginRight: '1%', marginTop: "2%" }}>
                                            <TextField onChange={e => setEmail(e.target.value)} value={email} placeholder="Enter Your Email" color="grey" label='Email' variant="outlined" sx={{ marginRight: '1%', backgroundColor: '#fff' }} id="username" />
                                        </FormControl>
                                    }
                                    {medium === "phoneNo" &&
                                        <FormControl sx={{ width: { xs: '80%', sm: '60%', md: '50%', lg: '50%' }, marginRight: '1%', marginTop: "2%" }}>
                                            <TextField onChange={e => setPhoneNumber(e.target.value)} value={phoneNumber} placeholder="Enter Your Phone Number" color="grey" label='Phone Number' variant="outlined" sx={{ marginRight: '1%', backgroundColor: '#fff' }} id="phone" />
                                        </FormControl>
                                    }
                                </Grid>

                                <Grid container={true} style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "center",
                                    marginTop: { xs: '15%', sm: '10%', md: '78%', lg: '78%' },
                                }}>
                                    <Grid item>
                                        <Button type="submit" variant="contained" sx={{
                                            textTransform: 'capitalize', width: '180px', height: '50px', margin: '15% 16px 0px 0', padding: '15px 43px', borderRadius: '5px', backgroundColor: 'rgba(3, 37, 65, 0.4)', '&:hover': {
                                                backgroundColor: 'rgba(3, 37, 65, 0.4)'
                                            }
                                        }}>Send New PIN </Button>
                                    </Grid>
                                </Grid>

                                <Grid style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: { xs: '15%', sm: '10%', md: '78%', lg: '98%' },

                                }}>
                                    <Typography variant="p" style={{ marginTop: "5%", color: "#707070" }}>Remember PIN? </Typography>
                                    <Typography variant="p" style={{ marginTop: "5%", color: "#032541", marginLeft: '1%' }}><Link to="/sign-in" style={{ textDecoration: 'none', color: '#032541', fontSize: '16px', fontWeight: 'normal', fontFamily: 'Inter' }}>Login.</Link></Typography>
                                </Grid>
                                <Snackbar open={open} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} onClose={handleClose}>
                                    <Alert
                                        onClose={handleClose}
                                        severity="error"
                                        variant="filled"
                                        sx={{ width: '100%' }}
                                    >
                                        {message}
                                    </Alert>
                                </Snackbar>
                                <Snackbar open={openSuccess} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} onClose={handleClose}>
                                    <Alert
                                        onClose={handleClose}
                                        severity="success"
                                        variant="filled"
                                        sx={{ width: '100%' }}
                                    >
                                        {successMessage}
                                    </Alert>
                                </Snackbar>

                            </form>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default ForgotPassword;