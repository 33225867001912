import Swal from "sweetalert2";
import successIcon from '../../../assets/services/success.svg' 
const PaymentSuccessful = (message) => {
    return Swal.fire({
        iconHtml: `<img src="${successIcon}" />`,
        title: 'Payment Successful',
        text: message,
        confirmButtonText: 'OK',
        showConfirmButton:true,
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        reverseButtons: true,
        customClass:{
            confirmButton: 'confirm-modal-button',
            cancelButton: 'cancel-modal-button',
            icon: 'swal-icon-success',
            content:'swal-content',
            title:'swal-title',
            text:'swal-text',
        }
    });
}

export default PaymentSuccessful;