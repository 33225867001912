
import './App.css';
import { CssBaseline, GlobalStyles } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: { color: "red" },
        fontFamily:'Inter'
      },
    },
  }
});

const globalStyles = (
  <GlobalStyles
    styles={(theme) => ({
      typography: {
        fontFamily: [
          'Inter'
        ],
      },
      html: {
        fontFamily: theme.typography.fontFamily
      },
      body: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        fontFamily: theme.typography.fontFamily
      },
      palette: {
        danger: {
          color: '#dc3545'
        }
      },
    })}
  />

)
function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {globalStyles}
    </ThemeProvider>

  );
}

export default App;
