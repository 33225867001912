import { Box, FormControl, Input, InputAdornment, TextField, TextareaAutosize as BaseTextareaAutosize, Button, Alert, Container, FormHelperText } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import DrawerAppBar from "../components/navbar";
import locationIcon from "../assets/location.svg";
import contactsIcon from "../assets/contacts.svg";
import emailIcon from "../assets/email.svg";
import formBackground from "../assets/form.svg";
import { Facebook, Instagram, Language, LinkedIn, Twitter } from "@mui/icons-material";
import top from "../assets/top.svg";
import bottom from "../assets/bottom.svg";
import styled from "@emotion/styled";
import { useState } from "react";


const Contacts = () => {
  const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
  };

  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };
  const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 320px;
    font-family: 'Inter';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
  `,
  );

  const [displayAlert,setDisplayAlert] = useState(false);
  const [name,setName] = useState("");
  const [email,setEmail] = useState("");
  const [phone,setPhone] = useState("");
  const [company,setCompany] = useState("");
  const [message,setMessage] = useState("");

  const [nameError,setNameError] = useState(false);
  const [emailError,setEmailError] = useState(false);
  const [phoneError,setPhoneError] = useState(false);
  const [companyError,setCompanyError] = useState(false);
  const [messageError,setMessageError] = useState(false);

  const [nameHelperText,setNameHelperText] = useState("");
  const [emailHelperText,setEmailHelperText] = useState("");
  const [phoneHelperText,setPhoneHelperText] = useState("");
  const [companyHelperText,setCompanyHelperText] = useState("");
  const [messageHelperText,setMessageHelperText] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    var isValid = true;
    
    setNameError(false);
    setNameHelperText("");

    setEmailError(false);
    setEmailHelperText("");

    setPhoneError(false);
    setPhoneHelperText("");

    setCompanyError(false);
    setCompanyHelperText("");

    setMessageError(false);
    setMessageHelperText("");

    if(name==='')
    {
      setNameError(true);
      setNameHelperText("Please enter your name.");
      isValid=false;
    }
    if(email==='')
    {
      setEmailError(true);
      setEmailHelperText("Please enter your email.");
      isValid=false;
    }
    if(phone==='')
    {
      setPhoneError(true);
      setPhoneHelperText("Please enter your phone number.");
      isValid=false;
    }

    if(company==='')
    {
      setCompanyError(true);
      setCompanyHelperText("Please enter company name.");
      isValid=false;
    }

    if(message==='')
    {
      setMessageError(true);
      setMessageHelperText("Please enter your message.");
      isValid=false;
    }

    if(isValid)
    {
      setDisplayAlert(true);
      setName("");
      setEmail("");
      setCompany("");
      setPhone("");
      setMessage("");
    }
  }

  return (
    <Grid container spacing={2} >
      <Grid container>
        <DrawerAppBar />
      </Grid>
      <Grid sx={{ display: "flex" }} container>
        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ width: '50%', marginTop: '8%', alignContent: 'center', textAlign: 'center', paddingLeft: '13%' }}>
          <Typography sx={{ fontFamily: 'Inter', fontSize: '24px', fontWeight: '500', lineHeight: 1.46, letterSpacing: 'normal', textAlign: 'left', color: '#707070' }}>
            Contact Us
          </Typography>
          <Typography sx={{ fontFamily: 'Inter', fontSize: '35px', fontWeight: 'bold', lineHeight: 1, letterSpacing: 'normal', textAlign: 'left', color: '#010202', margin: '17px 16px 48px 0' }}>
            Get In Touch  with Us.
          </Typography>
          <Grid spacing={2}>
            <Grid item lg={12} sx={{ display: 'flex' }}>
              <Typography sx={{ margin: '0 20px 0 0', objectFit: 'contain' }}>
                <img src={locationIcon} alt="" style={{ height: '80px', marginRight: '20%' }} />
              </Typography>
              <Typography sx={{ paddingLeft: '1%', margin: '0 299px 5px 0', fontFamily: 'Inter', fontSize: '18px', fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 2.19, letterSpacing: 'normal', textAlign: 'left', color: '#707070' }}>
                <Typography>
                  Our Location
                </Typography>
                <Typography sx={{ margin: '5px 0 0', fontFamily: 'Inter', fontSize: '20px', fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.33, letterSpacing: 'normal', textAlign: 'left', color: '#010202' }}>
                  Suite B31, Silverpool Office suites,
                </Typography>
                <Typography sx={{ margin: '5px 0 0', fontFamily: 'Inter', fontSize: '20px', fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.33, letterSpacing: 'normal', textAlign: 'left', color: '#010202' }}>
                  Jabavu Lane,Hurlingham.
                </Typography>
              </Typography>
            </Grid>
            <Grid item lg={12} sx={{ display: 'flex', marginTop: '5%' }}>
              <Typography sx={{ margin: '0 20px 0 0', objectFit: 'contain' }}>
                <img src={contactsIcon} alt="" style={{ height: '80px', marginRight: '20%' }} />
              </Typography>
              <Typography sx={{ paddingLeft: '1%', margin: '0 299px 5px 0', fontFamily: 'Inter', fontSize: '18px', fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 2.19, letterSpacing: 'normal', textAlign: 'left', color: '#707070' }}>
                <Typography>
                  Mobile Phone
                </Typography>
                <Typography sx={{ margin: '5px 0 0', fontFamily: 'Inter', fontSize: '20px', fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.33, letterSpacing: 'normal', textAlign: 'left', color: '#010202' }}>
                  (+254) 7 09 836-000
                </Typography>
              </Typography>
            </Grid>
            <Grid item lg={12} sx={{ display: 'flex', marginTop: '5%' }}>
              <Typography sx={{ margin: '0 20px 0 0', objectFit: 'contain' }}>
                <img src={emailIcon} alt="" style={{ height: '80px', marginRight: '20%' }} />
              </Typography>
              <Typography sx={{ paddingLeft: '1%', margin: '0 299px 5px 0', fontFamily: 'Inter', fontSize: '18px', fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 2.19, letterSpacing: 'normal', textAlign: 'left', color: '#707070' }}>
                <Typography>
                  Office Email
                </Typography>
                <Typography sx={{ margin: '5px 0 0', fontFamily: 'Inter', fontSize: '20px', fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.33, letterSpacing: 'normal', textAlign: 'left', color: '#010202' }}>
                  info@riverbank.co.ke
                </Typography>
              </Typography>
            </Grid>
          </Grid>
          <Grid spacing={2} sx={{ display: 'flex', marginTop: '30%' }}>
            <Facebook sx={{ color: '#1977f3', width: '3.3%', height: '3.3%', marginRight: '1%' }} />
            <Instagram sx={{ color: 'red', width: '3.3%', height: '3.3%', marginRight: '1%' }} />
            <LinkedIn sx={{ color: '#0070a8', width: '3.3%', height: '3.3%', marginRight: '1%' }} />
            <Twitter sx={{ color: '#1da1f2', width: '3.3%', height: '3.3%', marginRight: '1%' }} />
            <Language sx={{ width: '3.3%', height: '3.3%', marginRight: '1%' }} />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} sx={{
          width: '50%', marginTop: '8%', "&:before": {
            position: "absolute",
            content: '"Hello"',
            top: 0,
            left: 0,
            right: 0,
            width: "30%",
            height: 2,
            backgroundColor: "#fcaeb6"
          }
        }}>
          <form autoComplete="off" style={{ borderImage: { top }, backgroundColor: '#fafafa', width: '509px', height: 'auto', margin: '2px 0px 0.5px 139px', padding: '0px 0px 123px', objectFit: 'contain', borderRadius: '10px'
          }} onSubmit={handleSubmit}>
            <Typography sx={{ float: 'right' }}>
              <img src={top} />
            </Typography>
            <Typography sx={{ padding: '8% 8% 0% 8%', fontFamily: 'Inter', fontSize: '22px', fontWeight: 'bold', textAlign: 'left', color: '#dc3545', lineHeight: 1.05, fontStretch: 'normal', fontStyle: 'normal', letterSpacing: 'normal' }}>
              Send Us Message
            </Typography>
            <Typography sx={{ padding: '4% 8% 0% 8%', fontSize: '18px', fontFamily: 'Inter', fontWeight: 550, color: '#010202' }}>
              Drop us a quick mail and we will get back to you as soon as possible.
            </Typography>
            <Container sx={{ width: '85%', borderRadius: '10px', backgroundColor: '#fff', marginLeft: '8%', marginTop: '3%',display:displayAlert ? 'block':'none' }}>
              <Alert variant="filled" sx={{ borderRadius: '10px', fontFamily: 'Inter', fontSize: '15px' }}>Thank you for contacting us. We will get to you as soon as possible. </Alert>
            </Container>
            <TextField value={name} error={nameError} helperText={nameHelperText} onChange={e =>setName(e.target.value)} id="name" label="Your Name" variant="outlined" sx={{ width: '85%', borderRadius: '10px', backgroundColor: '#fff', marginLeft: '8%', marginTop: '3%' }} />
            <TextField value={email} error={emailError} helperText={emailHelperText} onChange={e =>setEmail(e.target.value)} id="email" label="Your Email Address" variant="outlined" sx={{ width: '85%', borderRadius: '10px', backgroundColor: '#fff', marginLeft: '8%', marginTop: '3%' }} />
            <TextField value={phone} error={phoneError} helperText={phoneHelperText} onChange={e =>setPhone(e.target.value)} id="phone" label="Your Phone Number" variant="outlined" sx={{ width: '85%', borderRadius: '10px', backgroundColor: '#fff', marginLeft: '8%', marginTop: '3%' }}
              InputProps={{
                startAdornment: <InputAdornment position="start">+254</InputAdornment>
              }}
            />
            <TextField value={company} error={companyError} helperText={companyHelperText} onChange={e =>setCompany(e.target.value)} id="company" label="Company Name" variant="outlined" sx={{ width: '85%', borderRadius: '10px', backgroundColor: '#fff', marginLeft: '8%', marginTop: '3%' }} />
            <Textarea border={messageError ? '1px solid red' : '1px solid #ccc'} value={message} error={messageError} helperText={messageHelperText} onChange={e =>setMessage(e.target.value)} aria-label="minimum height" minRows={5} placeholder="Your Message" style={{ width: '85%', borderRadius: '10px', backgroundColor: '#fff', marginLeft: '8%', marginTop: '3%',border: messageError ? '1px solid red' : '1px solid #ccc', }} />
            <FormHelperText error sx={{ width: '85%', borderRadius: '10px', backgroundColor: '#fff', marginLeft: '10%' }}>{messageHelperText}</FormHelperText>
            <Button type="submit" variant="outlined" size="large" sx={{
              textTransform: 'capitalize', backgroundColor: '#fff', border: 0, color: '#8d97a2', width: '175px', borderRadius: '4px', backgroundColor: '#fff', marginTop: '3%', height: '50px', '&:hover': {
                backgroundColor: '#dc3545',
                color: '#fff'
              }
            }}>Submit</Button>
            <Typography sx={{ float: 'left', marginTop: '125px', bottom: 0 }}>
              <img src={bottom} />
            </Typography>
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default Contacts;