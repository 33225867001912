import { createAsyncThunk } from "@reduxjs/toolkit";
import { HttpRequest } from "../components/HttpRequest";
import { createSlice } from "@reduxjs/toolkit";
import secureLocalStorage from "react-secure-storage";
export const emailSignupUser = createAsyncThunk(
    "email/signup",
    async ({ email,phoneNumber,password}, thunkAPI) => {
        try {
            const requestParams = {
                "email": email,
                "phoneNo":phoneNumber,
                "password": password,
            }
            const url = "sign-up";
            const response = await HttpRequest({ url,requestParams });
            console.log("This is the response",response);
            let data = await response.data;
            if (data.status === "00") {
                secureLocalStorage.setItem("token", data.token);
                secureLocalStorage.setItem("user",JSON.stringify(data.user));
                return data;
            }else{
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error) {
            console.log("Error",error.response.data);
            thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const EmailSignupSlice = createSlice({
    name:"emailSignup",
    initialState:{
        token:"",
        user:null,
        isFetching:false,
        isSuccess:false,
        isError:false,
        errorMessage:""
    },
    reducers:{
        clearState:(state) => {
            state.isError=false;
            state.isSuccess = false;
            state.isFetching = false;
            return state;
        }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(emailSignupUser.fulfilled,(state,{payload}) => {
            console.log("Payload new user",payload.user);
            state.token = payload.token;
            state.user=payload.user;
            state.isFetching=false;
            state.isSuccess=true;
            return state;
        })
        .addCase(emailSignupUser.rejected,(state,{payload})=>{
            state.isFetching=false;
            state.isError=true;
            state.errorMessage=payload.message
        })
        .addCase(emailSignupUser.pending,(state)=>{
            state.isFetching=true;
        });
    }
});

export const {clearState} = EmailSignupSlice.actions;

export const signupSelector = (state) => state.emailSignup;