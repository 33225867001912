import { createAsyncThunk } from "@reduxjs/toolkit";
import { HttpRequest } from "../components/HttpRequest";
import { createSlice } from "@reduxjs/toolkit";
import secureLocalStorage from "react-secure-storage";
export const resetPassword = createAsyncThunk(
    "phone/login",
    async ({ oldPassword, newPassword }, thunkAPI) => {
        try {
            const user = JSON.parse(secureLocalStorage.getItem('user'));
            const requestParams = {
                "userEmailPhone": user.email ?? user.phone,
                "ziziPassword": oldPassword,
                "newPassword": newPassword
            }
            const url = "change-password";
            const response = await HttpRequest({ url, requestParams });
            console.log("This is the response", response);
            let data = await response.data;
            if (data.status === "00") {
                secureLocalStorage.setItem("token", data.token);
                secureLocalStorage.setItem("user", JSON.stringify(data.user));
                return data;
            }else {
                console.log("Error dat", data);
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error) {
            console.log("Error", error.response.data);
            thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const ResetPasswordSlice = createSlice({
    name: "resetPassword",
    initialState: {
        token: "",
        user: null,
        isFetching: false,
        isSuccess: false,
        isError: false,
        errorMessage: "",
        status: "",
    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isFetching = false;
            state.status = "";
            return state;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(resetPassword.fulfilled, (state, { payload }) => {
                state.token = payload.token;
                state.user = payload.user;
                state.isFetching = false;
                state.isSuccess = true;
                state.status = payload.status;
                return state;
            })
            .addCase(resetPassword.rejected, (state, { payload }) => {
                state.isFetching = false;
                state.isError = true;
                state.errorMessage = payload.message;
                state.status = payload.status;
            })
            .addCase(resetPassword.pending, (state) => {
                state.isFetching = true;
            });
    }
});

export const { clearState } = ResetPasswordSlice.actions;

export const resetPasswordSelector = (state) => state.resetPassword;