import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import imgLogo from '../assets/logo.svg';
import { Link } from 'react-router-dom';
import { Grid, ListItemAvatar, ListItemIcon, Menu, MenuItem, MenuList } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
const drawerWidth = 240;
const navItems = [
    {
        linkName: "Home",
        id: 1,
        url: "/",
    },
    {
        linkName: "Services",
        id: 2,
        url: "#",
    },
    {
        linkName: "Contact Us",
        id: 3,
        url: "/contacts",
    },
    {
        linkName: "FAQS",
        id: 4,
        url: "/faqs",
    },
    {
        linkName: "How To",
        id: 4,
        url: "/how-to",
    },
    {
        linkName: "News",
        id: 5,
        url: "/news",
    },
];
function generate(element) {
    return [0, 1, 2].map((value) =>
        React.cloneElement(element, {
            key: value,
        }),
    );
}

const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
}));

function DrawerAppBar(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const location = useLocation();
    const currentLocation = location.pathname;
    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2, display: 'flex' }}>
                <img src={imgLogo} alt='' />  Zizi Customer Portal
            </Typography>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            {!item.linkName !== 'Services' && <Link key={item.id} to={item.url} style={{ textDecoration: 'none', color: '#707070', textTransform: 'capitalize', fontSize: '16px', margin: '0 74px 0 0', fontFamily: 'Inter', fontWeight: 500, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'left', width: '45px', height: '20px' }}>
                                <ListItemText primary={item.linkName} />
                            </Link>
                            }
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;
    const [dense, setDense] = React.useState(false);
    const [menu, setMenu] = useState(null);
    const open = Boolean(menu);
    const handleClick = (e) => {
        setMenu(e.currentTarget);
    };
    function handleClose() {
        setMenu(null);

    }
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav" color='default' sx={{ width: '100%', height: '100px', margin: '0 0 14px', boxShadow: '3px 0 20px 0 rgba(145, 158, 171, 0.12)', backgroundColor: '#fff', padding: '21px 207px 20.5px 203px' }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' }, ml: -20 }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="div"
                        sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' }, width: '58.5px', height: '58.5px', margin: '0 9.5px 0 0', objectFit: 'contain' }}
                    >
                        <img src={imgLogo} alt='' />

                    </Typography>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' }, width: '158px', 'height': '20px', margin: '19px 243px 19.5px 9.5px', fontFamily: 'Inter', fontSize: '16px', fontWeight: 600, fontStretch: 'normal', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'left', color: '#707070' }}
                    >
                        Zizi Customer Portal
                    </Typography>

                    <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}>
                        {navItems.map((item) => (
                            <Link aria-owns="menu" aria-haspopup="true" key={item.id} style={{ color: `${currentLocation === item.url ? '#dc3545' : '#707070'}`, textDecoration: 'none', textTransform: 'capitalize', fontSize: '16px', margin: '0 74px 0 0', fontFamily: 'Inter', fontWeight: `${currentLocation === item.url ? 600 : 500}`, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'left', width: '45px', height: '20px' }} to={item.url}
                                onClick={item.linkName === 'Services' ? handleClick : undefined}
                                onMouseOver={item.linkName === 'Services' ? handleClick : undefined}
                            >
                                {item.linkName}
                            </Link>
                        ))}
                    </Box>
                </Toolbar>
                <Menu sx={{ backgroundColor: 'transparent', marginTop: '3%' }} id="menu"
                    anchorEl={menu}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'start',
                        horizontal: 'start',
                    }} >
                    <Grid container spacing={2} sx={{ backgroundColor: '#fafafa', padding: '5%', textAlign: 'left' }}>
                        <Grid item xs={12} md={3}>
                            <Typography sx={{ mt: 1, mb: 1, fontFamily: 'Inter', fontSize: '18px', fontWeight: 600, fontStretch: 'normal', fontStyle: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.28, letterSpacing: 'normal', textAlign: 'left', color: '#010202' }} component="div">
                                Parking Management
                            </Typography>
                            <Demo sx={{ background: 'transparent' }}>
                                <List dense={dense}>
                                    <ListItem>
                                        <Link style={{ textDecoration: 'none' }} to="/parking">
                                            <ListItemText
                                                primary="Daily Street Parking" sx={{ color: '#707070', fontFamily: 'Inter', fontSize: '14px', fontWeight: 'normal', fontStyle: 'normal', lineHeight: 1.71, textAlign: 'left', letterSpacing: 'normal' }}
                                            />
                                        </Link>
                                    </ListItem>
                                    <ListItem>
                                        <Link style={{ textDecoration: 'none' }} to="/parking">
                                            <ListItemText
                                                primary="Weekly Parking" sx={{ color: '#707070', fontFamily: 'Inter', fontSize: '14px', fontWeight: 'normal', fontStyle: 'normal', lineHeight: 1.71, textAlign: 'left', letterSpacing: 'normal' }}
                                            />
                                        </Link>
                                    </ListItem>
                                    <ListItem>
                                        <Link style={{ textDecoration: 'none' }} to="/parking">
                                            <ListItemText
                                                primary="Monthly Parking" sx={{ color: '#707070', fontFamily: 'Inter', fontSize: '14px', fontWeight: 'normal', fontStyle: 'normal', lineHeight: 1.71, textAlign: 'left', letterSpacing: 'normal' }}
                                            />
                                        </Link>
                                    </ListItem>
                                    <ListItem>
                                        <Link style={{ textDecoration: 'none' }} to="/parking">
                                            <ListItemText
                                                primary="Seasonal Parking" sx={{ color: '#707070', fontFamily: 'Inter', fontSize: '14px', fontWeight: 'normal', fontStyle: 'normal', lineHeight: 1.71, textAlign: 'left', letterSpacing: 'normal' }}
                                            />
                                        </Link>
                                    </ListItem>
                                </List>
                            </Demo>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography sx={{ mt: 1, mb: 1, fontFamily: 'Inter', fontSize: '18px', fontWeight: 600, fontStretch: 'normal', fontStyle: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.28, letterSpacing: 'normal', textAlign: 'left', color: '#010202' }} component="div">
                                Health Services
                            </Typography>
                            <Demo sx={{ background: 'transparent' }}>
                                <List dense={dense}>
                                    <ListItem>
                                        <Link style={{ textDecoration: 'none' }} to="/vetfees">
                                            <ListItemText
                                                primary="Vet Services" sx={{ color: '#707070', fontFamily: 'Inter', fontSize: '14px', fontWeight: 'normal', fontStyle: 'normal', lineHeight: 1.71, textAlign: 'left', letterSpacing: 'normal' }}
                                            />
                                        </Link>
                                    </ListItem>
                                </List>
                            </Demo>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography sx={{ mt: 1, mb: 1, fontFamily: 'Inter', fontSize: '18px', fontWeight: 600, fontStretch: 'normal', fontStyle: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.28, letterSpacing: 'normal', textAlign: 'left', color: '#010202' }} component="div">
                                Business Licensing
                            </Typography>
                            <Demo sx={{ background: 'transparent' }}>
                                <List dense={dense}>
                                    <ListItem>
                                        <Link style={{ textDecoration: 'none' }} to="/user/license">
                                            <ListItemText
                                                primary="Licences" sx={{ color: '#707070', fontFamily: 'Inter', fontSize: '14px', fontWeight: 'normal', fontStyle: 'normal', lineHeight: 1.71, textAlign: 'left', letterSpacing: 'normal' }}
                                            />
                                        </Link>
                                    </ListItem>
                                    <ListItem>
                                        <Link style={{ textDecoration: 'none' }} to="/user/psv-stickers">
                                            <ListItemText
                                                primary="PSV Stickers" sx={{ color: '#707070', fontFamily: 'Inter', fontSize: '14px', fontWeight: 'normal', fontStyle: 'normal', lineHeight: 1.71, textAlign: 'left', letterSpacing: 'normal' }}
                                            />
                                        </Link>
                                    </ListItem>
                                </List>
                            </Demo>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography sx={{ mt: 1, mb: 1, fontFamily: 'Inter', fontSize: '18px', fontWeight: 600, fontStretch: 'normal', fontStyle: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.28, letterSpacing: 'normal', textAlign: 'left', color: '#010202' }} component="div">
                                Land Management
                            </Typography>
                            <Demo sx={{ background: 'transparent' }}>
                                <List dense={dense}>
                                    <ListItem>
                                        <Link style={{ textDecoration: 'none' }} to="/user/land-rates">
                                            <ListItemText
                                                primary="Land Rates" sx={{ color: '#707070', fontFamily: 'Inter', fontSize: '14px', fontWeight: 'normal', fontStyle: 'normal', lineHeight: 1.71, textAlign: 'left', letterSpacing: 'normal' }}
                                            />
                                        </Link>
                                    </ListItem>
                                </List>
                            </Demo>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography sx={{ mt: 1, mb: 1, fontFamily: 'Inter', fontSize: '18px', fontWeight: 600, fontStretch: 'normal', fontStyle: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.28, letterSpacing: 'normal', textAlign: 'left', color: '#010202' }} component="div">
                                Cess Management
                            </Typography>
                            <Demo sx={{ background: 'transparent' }}>
                                <List dense={dense}>
                                    <ListItem>
                                        <Link style={{ textDecoration: 'none' }} to="/cess">
                                            <ListItemText
                                                primary="Market Cess" sx={{ color: '#707070', fontFamily: 'Inter', fontSize: '14px', fontWeight: 'normal', fontStyle: 'normal', lineHeight: 1.71, textAlign: 'left', letterSpacing: 'normal' }}
                                            />
                                        </Link>
                                    </ListItem>
                                    <ListItem>
                                        <Link style={{ textDecoration: 'none' }} to="/cess">
                                            <ListItemText
                                                primary="Transport Cess" sx={{ color: '#707070', fontFamily: 'Inter', fontSize: '14px', fontWeight: 'normal', fontStyle: 'normal', lineHeight: 1.71, textAlign: 'left', letterSpacing: 'normal' }}
                                            />
                                        </Link>
                                    </ListItem>
                                </List>
                            </Demo>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography sx={{ mt: 1, mb: 1, fontFamily: 'Inter', fontSize: '18px', fontWeight: 600, fontStretch: 'normal', fontStyle: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.28, letterSpacing: 'normal', textAlign: 'left', color: '#010202' }} component="div">
                                Advertisement
                            </Typography>
                            <Demo sx={{ background: 'transparent' }}>
                                <List dense={dense}>
                                    <ListItem>
                                        <Link style={{ textDecoration: 'none' }} to="/user/advertising">
                                            <ListItemText
                                                primary="Advertising" sx={{ color: '#707070', fontFamily: 'Inter', fontSize: '14px', fontWeight: 'normal', fontStyle: 'normal', lineHeight: 1.71, textAlign: 'left', letterSpacing: 'normal' }}
                                            />
                                        </Link>
                                    </ListItem>
                                </List>
                            </Demo>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography sx={{ mt: 1, mb: 1, fontFamily: 'Inter', fontSize: '18px', fontWeight: 600, fontStretch: 'normal', fontStyle: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.28, letterSpacing: 'normal', textAlign: 'left', color: '#010202' }} component="div">
                                Property Management
                            </Typography>
                            <Demo sx={{ background: 'transparent' }}>
                                <List dense={dense}>
                                    <ListItem>
                                        <Link style={{ textDecoration: 'none' }} to="/user/rentals">
                                            <ListItemText
                                                primary="Property Rates" sx={{ color: '#707070', fontFamily: 'Inter', fontSize: '14px', fontWeight: 'normal', fontStyle: 'normal', lineHeight: 1.71, textAlign: 'left', letterSpacing: 'normal' }}
                                            />
                                        </Link>
                                    </ListItem>
                                    <ListItem>
                                        <Link style={{ textDecoration: 'none' }} to="/user/rentals">
                                            <ListItemText
                                                primary="Rentals" sx={{ color: '#707070', fontFamily: 'Inter', fontSize: '14px', fontWeight: 'normal', fontStyle: 'normal', lineHeight: 1.71, textAlign: 'left', letterSpacing: 'normal' }}
                                            />
                                        </Link>
                                    </ListItem>
                                </List>
                            </Demo>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography sx={{ mt: 1, mb: 1, fontFamily: 'Inter', fontSize: '18px', fontWeight: 600, fontStretch: 'normal', fontStyle: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.28, letterSpacing: 'normal', textAlign: 'left', color: '#010202' }} component="div">
                                Parking Services
                            </Typography>
                            <Demo sx={{ background: 'transparent' }}>
                                <List dense={dense}>
                                    <ListItem>
                                        <Link style={{ textDecoration: 'none' }} to="/parking">
                                            <ListItemText
                                                primary="Parking Fees" sx={{ color: '#707070', fontFamily: 'Inter', fontSize: '14px', fontWeight: 'normal', fontStyle: 'normal', lineHeight: 1.71, textAlign: 'left', letterSpacing: 'normal' }}
                                            />
                                        </Link>
                                    </ListItem>
                                </List>
                            </Demo>
                        </Grid>
                    </Grid>
                </Menu>

            </AppBar>
            <nav >
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>

            </nav>


        </Box>
    );
}

DrawerAppBar.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default DrawerAppBar;
