import axios from 'axios';
import { getCurrentTime } from '../pages/services/components/generateDate';
import secureLocalStorage from 'react-secure-storage';

const base_url = process.env.REACT_APP_API_BASE_URL;
const countyID = process.env.REACT_APP_COUNTY_ID;
const subcountyID = process.env.REACT_APP_SUBCOUNTY_ID;
const token = process.env.REACT_APP_TOKEN;

const HttpRequest = async ({ url, requestParams}) => {
   console.log('This is the current local storage token',secureLocalStorage.getItem('token'));
    const params = {
        "requestHeader": {
            "appId": "webPortal",
            "appVersion": "1.0.0",
            "txType": url,
            "txTime": getCurrentTime()
        },
        "token": secureLocalStorage.getItem('token')??token,
        "countyId": countyID,
        "subCountyId":subcountyID,
        ...requestParams,
    }
    console.log('These are params',params);
    const headers = {
        'Content-Type': 'application/json',
    };

    const response = await axios({
        method: 'post',
        headers: headers,
        url: base_url,
        data: params
    });

    return response;
};

export { HttpRequest };