import { Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import loginImage from "../../../../assets/login.svg";
import Snackbar from "@mui/material/Snackbar";
import { useEffect, useState } from "react";
import { Alert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { clearState, phoneLoginUser, loginSelector } from "../../../../slices/phoneLogin";
import { Link, Navigate, useNavigate } from "react-router-dom";
import DrawerAppBar from "../../../../components/navbar";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const PhoneLogin = () => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const { isFetching, isSuccess, isError, errorMessage, status } = useSelector(loginSelector);

    const handleLogin = async (e) => {
        e.preventDefault();
        var isValid = true;
        setOpen(false);
        setMessage("");

        if (phone === "") {
            setOpen(true);
            setMessage("Please enter your phone number");
            isValid = false;
        }
        if (password === "") {
            setOpen(true);
            setMessage("Please enter your PIN");
            isValid = false;
        }
        if (isValid) {
            dispatch(phoneLoginUser({ phone, password }));
        }
    }

    useEffect(() => {
        return () => {
            dispatch(clearState());
        };
    }, []);

    useEffect(() => {
        if (isError) {
            if (status == "19") {
                navigate("/password/change");
            }
            setOpen(true);
            setMessage(errorMessage);
            dispatch(clearState());
        }
        if (isSuccess) {
            dispatch(clearState);
            navigate('/dashboard');
        }
    }, [isError, isSuccess, status]);
    const handleClose = (event, reason) => {
        setOpen(false);
        setMessage("");
    };

    return (
        <>
            This a test
            <DrawerAppBar />
            <Box component="main" sx={{ marginBottom: { xs: '60%', sm: '10%', md: '58%', lg: '5%' } }}>
                <Grid container={true} sx={{ marginTop: { xs: '40%', sm: '15%', md: '10%', lg: '5%' }, alignContent: 'center', width: '100%', height: '100%', textAlign: 'center' }} spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12} lg={6} sx={{ textAlign: 'center', alignContent: 'center' }}>
                        <Typography sx={{ marginLeft: { xs: '10%', sm: '10%', md: '18%', lg: '18%' }, objectFit: 'contain' }}>
                            <img src={loginImage} alt="" style={{ width: '70%' }} />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} sx={{ textAlign: 'center', alignContent: 'center' }}>
                        <Box sx={{ width: '100%', textAlign: 'center' }}>
                            <Typography variant="h4" sx={{ width: '100%', height: 'auto', padding: '0 20% 0 20%', whiteSpace: "pre-wrap", marginLeft: { xs: '5%', sm: '2%', md: '2%', lg: '5%' }, marginTop: { xs: '5%', sm: '3%', md: '3%', lg: '2%' }, marginBottom: { xs: '6%', sm: '5%', md: '3%', lg: '2%' }, fontFamily: 'Inter', fontSize: '22px', fontWeight: 600, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.59, letterSpacing: 'normal', color: '#707070', textAlign: 'center' }}>
                                Login!
                            </Typography>
                            <Typography variant="h6" sx={{ width: { xs: '50%', sm: '50%', md: '30%', lg: '35%' }, height: 'auto', marginLeft: { xs: '28%', sm: '25%', md: '39%', lg: '35%' }, marginBottom: { xs: '7%', sm: '2%', md: '2%', lg: '5%' }, fontFamily: 'Inter', fontSize: '18px', fontWeight: 500, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.94, letterSpacing: 'normal', textAlign: 'center', color: '#9b9b9b' }}>
                                Enter your valid phone number and PIN to access your account.
                            </Typography>
                            <form onSubmit={handleLogin} autoComplete="off">
                                <Grid container={true} sx={{ marginLeft: { xs: '10%', sm: '20%', md: '30%', lg: '29%' } }}>
                                    <FormControl sx={{ width: {xs: '80%', sm: '60%', md: '50%', lg: '50%'}, marginRight: '1%' }}>
                                        <TextField type="number" onChange={e => setPhone(e.target.value)} value={phone} placeholder="Enter Your Phone Number" color="grey" label='Phone Number' variant="outlined" sx={{ marginRight: '1%', backgroundColor: '#fff' }} id="phone" />
                                    </FormControl>
                                    <FormControl sx={{ width: {xs: '80%', sm: '60%', md: '50%', lg: '50%'}, marginRight: '1%', marginTop: {xs: '8%', sm: '5%', md: '6%', lg: '5%'}, marginBottom: '1%' }} variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-password">PIN</InputLabel>
                                        <OutlinedInput onChange={e => setPassword(e.target.value)} value={password} placeholder="Enter your PIN"
                                            id="outlined-adornment-password"
                                            type={showPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Password"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid style={{
                                    display: "flex",
                                    justifyContent: "right",
                                    marginTop: { xs: '15%', sm: '10%', md: '78%', lg: '78%' },
                                    marginRight: "15%"
                                }}>
                                    <Link to="/password/forgot" style={{ color: '#707070', textDecoration: 'none', textTransform: 'capitalize', fontSize: '16px', margin: '0 74px 0 0', fontFamily: 'Inter', fontWeight: 500, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'left' }}>
                                        <Typography variant="p" style={{ marginTop: "10%", color: "#03254" }}>Forgot Pin?</Typography>
                                    </Link>
                                </Grid>
                                <Grid container={true} style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "center",
                                    marginTop: { xs: '15%', sm: '10%', md: '78%', lg: '78%' },
                                }}>
                                    <Grid item>
                                        <Button type="submit" variant="contained" sx={{
                                            textTransform: 'capitalize', width: '150px', height: '50px', margin: '15% 16px 0px 0', padding: '15px 43px', borderRadius: '5px', backgroundColor: 'rgba(3, 37, 65, 0.4)', '&:hover': {
                                                backgroundColor: 'rgba(3, 37, 65, 0.4)'
                                            }
                                        }}>Login</Button>
                                    </Grid>
                                </Grid>

                                <Snackbar open={open} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} onClose={handleClose}>
                                    <Alert
                                        onClose={handleClose}
                                        severity="error"
                                        variant="filled"
                                        sx={{ width: '100%' }}
                                    >
                                        {message}
                                    </Alert>
                                </Snackbar>

                            </form>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default PhoneLogin;