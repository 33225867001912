import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import DrawerAppBar from "../components/navbar";
import signIcon from "../assets/faqs/signin.svg";
import parkingIcon from "../assets/faqs/parking.svg";
import bussinessIcon from "../assets/faqs/business.svg";
import marketIcon from "../assets/faqs/market.svg";
import advertisingIcon from "../assets/faqs/advertising.svg";
import vetIcon from "../assets/faqs/vet.svg";
import licensesIcon from "../assets/faqs/licenses.svg";
import cessIcon from "../assets/faqs/cess.svg";
import psvIcon from "../assets/faqs/stickers.svg";
import propertyIcon from "../assets/faqs/rates.svg";
import { Link } from "react-router-dom";

const Items = [
  {
    title: ' Sign In/Up',
    titleColor: '#dc3545',
    description: 'Learn how to register and sign in on ZiZi customer Portal easily.',
    image: signIcon
  },
  {
    title: 'PK-Parking',
    titleColor: '#5e0ac4',
    description: 'Learn how to fill in your Parking details and making payment.',
    image: parkingIcon
  },
  {
    title: 'Adding a Business',
    titleColor: '#ff8503',
    description: 'Learn how to add a new business on ZIZI customer Portal.',
    image: bussinessIcon
  },
  {
    title: 'MK-Market',
    titleColor: '#3939dd',
    description: 'Learn how to fill in your Market details and making payment.',
    image: marketIcon
  },
  {
    title: 'Advertising',
    titleColor: '#fb0',
    description: 'Learn how to fill in your Market details and making payment.',
    image: advertisingIcon
  },
  {
    title: 'VF-Vet Fees',
    titleColor: '#17ae7b',
    description: 'Learn how to fill in your Vets details and making payment (Vet Fees).',
    image: vetIcon
  },
  {
    title: 'Licenses',
    titleColor: '#dc3545',
    description: 'Learn how to apply and pay for your business licenses on the portal.',
    image: licensesIcon
  },
  {
    title: 'CS - Cess',
    titleColor: '#1861aa',
    description: 'Learn how to fill in your Cess details and making payment.',
    image: cessIcon
  },
  {
    title: 'PSV Stickers',
    titleColor: '#3da2f8',
    description: 'Learn how to apply and pay for PSV Stickers.',
    image: psvIcon
  },
  {
    title: 'Property Rates',
    titleColor: '#13417d',
    description: 'Learn how to fill in your Cess details and making payment.',
    image: propertyIcon
  }
];
const HowTo = () => {
  return (
    <Box component="main" >
      <DrawerAppBar />
      <Grid container={true} sx={{ alignContent: 'center', textAlign: 'center', justifyContent: 'center' }} spacing={2} >
        <Grid item xs={12} md={12} lg={12}>
          <Typography sx={{ marginTop: '8%', fontFamily: 'Inter', textAlign: 'center', alignContent: 'center', fontSize: '35px', fontWeight: 'bold', lineHeight: 1, textAlign: 'center', color: '#dc3545' }}>
            Let’s get started!
          </Typography>
          <Typography sx={{ fontFamily: 'Inter', fontSize: '20px', fontWeight: 600, lineHeight: 1.75, textAlign: 'center', color: '#707070', margin: '23.9px 350px 21.2px 351px' }}>
            Welcome to ZIZI User Guide. (How To Navigate on the ZIZI Customer Portal)
          </Typography>
        </Grid>
        <Grid container columns={12} sx={{ paddingLeft: '10%' }} spacing={0}>
          {Items.map((data, index) => (
            <Grid key={index} item lg={2} sx={{ width: '250px', height: '250px', margin: '2% 1% 0 0', padding: ' 37px 23px 36px', borderRadius: '10px', boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)', backgroundColor: '#fff' }}>
              <Link to="/how-to/sign-in-out" style={{textDecoration:'none'}}>
                <img src={data.image} alt="" style={{ width: '60px', height: '60px', margin: '0 72px 16px', objectFit: 'contain' }} />
                <Typography sx={{ margin: '16px 1px', fontFamily: 'Inter', fontSize: '16px', fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.44, letterSpacing: 'normal', textAlign: 'center', color: `${data.titleColor}` }}>
                  {data.title}
                </Typography>
                <Typography sx={{ margin: '16px', fontFamily: 'Inter', fontSize: '14px', fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.71, letterSpacing: 'normal', textAlign: 'center', color: '#070707' }}>
                  {data.description}
                </Typography>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box >
  );
}
export default HowTo;