import ConfirmPaymentAlert from "../alerts/confirmPayment";
import ContactingServer from "../alerts/contactingServer";
import MpesaRequest from "./mpesaRequest";
import PaymentSuccessful from "../alerts/paymentSuccessful";
import PaymentFailed from "../alerts/paymentFailed";

const base_url = process.env.REACT_APP_WEB_SOCKET_URL;

const ProcessPayment = (referenceNo, zone, duration, serviceType, customerNo, serviceAmount,source) =>{

    return ConfirmPaymentAlert(referenceNo, zone, duration, serviceType, customerNo, serviceAmount).then((result) => {
        if (result.isConfirmed) {
            ContactingServer();
            MpesaRequest(customerNo, serviceAmount, referenceNo, source).then((result) => {
                if (result.status === "00") {
                    const socket = new WebSocket(base_url+'/websocket/' + result.paymentId);
                    socket.addEventListener('message', function (event) {
                        const data = JSON.parse(event.data);
                        if (data.status === "00") {
                            const message= `Your payment of KES ${serviceAmount} For Ref ${referenceNo}
                            (${serviceType}) Has been received.Mpesa
                            confirmation Code:${data.mpesaRefId}.`;
                            PaymentSuccessful(message);
                        } else {
                            socket.close();
                            PaymentFailed(data.message);
                        }
                    });
                } else {
                    ContactingServer().close();
                    PaymentFailed(result.message);
                }
            });

        } 
    });
}
export default ProcessPayment;