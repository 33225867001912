import { Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import loginImage from "../../assets/login.svg";
import Snackbar from "@mui/material/Snackbar";
import { useEffect, useState } from "react";
import { Alert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { clearState, updateProfile, profleSelector } from "../../slices/updateProfile";
import { Link, Navigate, useNavigate } from "react-router-dom";
import DrawerAppBar from "../../components/navbar";
import { LineAxis } from "@mui/icons-material";
import { verifyPhoneNumber, verifyPhoneNumberSelector } from "../../slices/verifyPhoneNumber";
import { HttpRequest } from "../../components/HttpRequest";

const VerifyPhone = () => {
    const [open, setOpen] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [message, setMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [verificationCode, setverificationCode] = useState("");
    const [codeSent, setCodeSent] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { isSuccess, isError, errorMessage } = useSelector(verifyPhoneNumberSelector);

    const handleLogin = async (e) => {
        e.preventDefault();

        var isValid = true;
        setOpen(false);
        setMessage("");

        if (verificationCode === "") {
            setOpen(true);
            setMessage("Please enter your Verification Code");
            isValid = false;
        }
        if (isValid) {
            dispatch(verifyPhoneNumber({ verificationCode }));
        }
    }
    const resendCode = async () => {
  
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const requestParams = {
                "phoneNo": user.phone,
            };
            const url = "resend-verification-code";
            const response = await HttpRequest({ url, requestParams });
        
            if (response.data.status === "00") {
                setCodeSent(true);
            } else {
                console.log("Fail safe",response.data);
                setOpen(true);
                setMessage("Failed to request new verification code!");
            }
        } catch (error) {
            console.log("Fail errr",error.message);
            setOpen(true);
            setMessage("Failed to request new verification code!")
        }
    };

    useEffect(() => {
        if(codeSent){
            setOpenSuccess(true);
            setSuccessMessage('Code resent successfully.');
        }
    }, [codeSent]);

    useEffect(() => {
        return () => {
            dispatch(clearState());
        };
    }, []);

    useEffect(() => {
        if (isError) {
            setOpen(true);
            setMessage(errorMessage);
            dispatch(clearState());
        }
        if (isSuccess) {
            dispatch(clearState);
            navigate('/dashboard');
        }
    }, [isError, isSuccess]);

    const handleClose = (event, reason) => {
        setOpen(false);
        setMessage("");
    };

    return (
        <>
            <DrawerAppBar />
            <Box component="main" sx={{ marginBottom: { xs: '10%', sm: '10%', md: '58%', lg: '5%' } }}>
                <Grid container={true} sx={{ marginTop: { xs: '80%', sm: '50%', md: '38%', lg: '18%' }, alignContent: 'center', width: '100%', height: '235px', textAlign: 'center' }} spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12} lg={6} sx={{ textAlign: 'center', alignContent: 'center' }}>
                        <Typography sx={{ marginLeft: { xs: '10%', sm: '10%', md: '18%', lg: '18%' }, objectFit: 'contain' }}>
                            <img src={loginImage} alt="" style={{ width: '70%' }} />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} sx={{ textAlign: 'center', alignContent: 'center' }}>
                        <Box sx={{ width: '100%', textAlign: 'center' }}>
                            <Typography variant="h4" sx={{ width: '75%', height: 'auto', padding: '0 20% 0 20%', whiteSpace: "pre-wrap", marginLeft: '10%', marginTop: { xs: '12%', sm: '10%', md: '5%', lg: '2%' }, marginBottom: { xs: '12%', sm: '10%', md: '8%', lg: '2%' }, fontFamily: 'Inter', fontSize: '22px', fontWeight: 600, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.59, letterSpacing: 'normal', color: '#707070', textAlign: 'center' }}>
                                Verify Phone Number!
                            </Typography>
                            <Typography variant="h6" sx={{ width: '30%', height: 'auto', marginLeft: { xs: '15%', sm: '10%', md: '8%', lg: '35%' }, marginBottom: { xs: '15%', sm: '10%', md: '8%', lg: '5%' }, fontFamily: 'Inter', fontSize: '18px', fontWeight: 500, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.94, letterSpacing: 'normal', textAlign: 'center', color: '#9b9b9b' }}>
                                Enter the verification code sent to your phone number
                            </Typography>
                            <form onSubmit={handleLogin} autoComplete="off">

                                <Grid container={true} sx={{ marginLeft: { xs: '10%', sm: '30%', md: '26%', lg: '29%' } }}>
                                    <FormControl sx={{ width: { xs: '75%', sm: '30%', md: '26%', lg: '50%' }, marginRight: '1%' }}>
                                        <TextField type="number" onChange={e => setverificationCode(e.target.value)} value={verificationCode} placeholder="Enter Your Verification Code" color="grey" label='Verification Code' variant="outlined" sx={{ marginRight: '1%', backgroundColor: '#fff' }} id="verificationCode" />
                                    </FormControl>
                                </Grid>
                                <Grid style={{
                                    display: "flex",
                                    justifyContent: "right",
                                    marginTop: { xs: '15%', sm: '10%', md: '78%', lg: '98%' },
                                    marginRight: "20%"
                                }}>
                                    <Typography variant="p" style={{ marginTop: "5%", color: "#707070" }}>Did not receive verification code? </Typography>
                                    <Typography variant="p" style={{ marginTop: "5%", color: "#032541", marginLeft: '1%' }}><Link onClick={resendCode} style={{ textDecoration: 'none', color: '#032541', fontSize: '16px', fontWeight: 'normal', fontFamily: 'Inter' }}>Resend.</Link></Typography>
                                </Grid>
                                <Grid container={true} style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "center",
                                    marginTop: { xs: '15%', sm: '10%', md: '78%', lg: '78%' },
                                }}>
                                    <Grid item>
                                        <Button type="submit" variant="contained" sx={{
                                            textTransform: 'capitalize', width: '150px', height: '50px', margin: '15% 16px 0px 0', padding: '15px 43px', borderRadius: '5px', backgroundColor: 'rgba(3, 37, 65, 0.4)', '&:hover': {
                                                backgroundColor: 'rgba(3, 37, 65, 0.4)'
                                            }
                                        }}>Verify</Button>
                                    </Grid>
                                </Grid>
                                <Snackbar open={open} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} onClose={handleClose}>
                                    <Alert
                                        onClose={handleClose}
                                        severity="error"
                                        variant="filled"
                                        sx={{ width: '100%' }}
                                    >
                                        {message}
                                    </Alert>
                                </Snackbar>
                                <Snackbar open={openSuccess} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} onClose={handleClose}>
                                    <Alert
                                        onClose={handleClose}
                                        severity="success"
                                        variant="filled"
                                        sx={{ width: '100%' }}
                                    >
                                        {successMessage}
                                    </Alert>
                                </Snackbar>
                            </form>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default VerifyPhone;