import { createTheme, ThemeProvider, Box, Container, Grid, Paper, Typography, Table, TableContainer, TableHead, TableRow } from "@mui/material";
import DashboardNavbar from "../../components/dashboardNavbar";
import vetIcon from '../../assets/services/dashboard/vet.svg';
import marketsIcon from '../../assets/services/dashboard/markets.png';
import advertIcon from '../../assets/services/dashboard/advertising.svg';
import parkingIcon from '../../assets/services/dashboard/parking.svg';
import cessIcon from '../../assets/services/dashboard/cess.svg';
import psvIcon from '../../assets/services/dashboard/psv.png';
import licenseIcon from '../../assets/services/dashboard/licenses.svg';
import ratesIcon from '../../assets/services/dashboard/property.svg';
import sbpIcon from '../../assets/services/dashboard/sbp.svg';
import rentalsIcon from '../../assets/services/dashboard/rentals.png';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';
import { useEffect, useState } from "react";
import { Link, json } from "react-router-dom";
import { HttpRequest } from "../../components/HttpRequest";
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";


function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport sx={{color:'#707070'}}/>
      </GridToolbarContainer>
    );
  }


const Transactions = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [transactions, setTransactions] = useState([]);
    const [transactionsLoading, setTransactionsLoading] = useState(true);
    const url = "get-portal-transactions";

    const columns = [
        { field: 'receiptNo', headerName: 'Receipt No.', width: 120 },
        { field: 'county', headerName: 'County', width: 220 },
        {
            field: 'subCounty',
            headerName: 'Subcounty',
            width: 220,
        },
        {
            field: 'category',
            headerName: 'Category',
            width: 200,
        },
        {
            field: 'service',
            headerName: 'Service',
            width: 300,
        },
        {
            field: 'source',
            headerName: 'Source',
            width: 90,
        },
        {
            field: 'paymentMode',
            headerName: 'Payment Mode',
            width: 110,
        },
        {
            field: 'amount',
            headerName: 'Amount',
            width: 90,
        },
        {
            field: 'transTime',
            headerName: 'Trans Time',
            width: 160,
        },
    ];
    const requestParams = {

    }
    useEffect(() => {
        setTransactionsLoading(true);
        HttpRequest({ url, requestParams })
            .then((result) => {
                console.log('Results', result.data.status);
                if (result.data.status === "00") {
                    console.log('Results inside', result);
                    setTransactions(result.data.transactions)
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setTransactionsLoading(false);
            });
    }, []);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Box sx={{ display: 'flex', backgroundColor: '#F6F7F8', fontFamily: 'Inter' }} >
            <DashboardNavbar />
            <Container sx={{ marginTop: '5%', marginBottom: 4, marginLeft: '-2.3%' }}>
                <Paper sx={{ width: '142%', overflow: 'hidden', marginTop: '1%', padding: '2%' }}>
                    <Typography variant="h6" sx={{
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        fontWeight: 550,
                        color: '#010202',
                        fontStretch: 'normal',
                        fontStyle: 'normal',
                        lineHeight: 2.06,
                        letterSpacing: 'normal',
                        textAlign: 'left',
                        margin: '2% 988px 25px 0'
                    }}>
                        My Transactions
                    </Typography>
                    
                    <div style={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={transactions}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                            }}
                            pageSizeOptions={[5, 10]}
                            checkboxSelection 
                            slots={{ toolbar:  CustomToolbar }}
                            loading={transactionsLoading}
                        />
                    </div>
                </Paper>
            </Container>
        </Box >
    );
}

export default Transactions;