import DrawerAppBar from "../../components/navbar";
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import cessImage from '../../assets/services/cess.png';
import { ChevronLeft, Circle } from "@mui/icons-material";
import { Link } from "react-router-dom";
import signInIcon from "../../assets/sign-in.svg";

const SignInOut = () => {
    return (
        <>
            <DrawerAppBar />
            <Box component="main" sx={{ marginBottom: { xs: '10%', sm: '10%', md: '58%', lg: '6%' }, marginTop: { xs: '100%', sm: '50%', md: '45%', lg: '6%' } }}>
                <Grid container={true} sx={{ marginTop: { xs: '100%', sm: '20%', md: '8%', lg: '2%' }, alignContent: 'center', width: '100%', height: '100%', textAlign: 'center' }} spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item sx={{ display: 'flex', ml: { xs: '10%', sm: '30%', md: '25%', lg: '10.5%' }, marginBottom: { xs: '10%', sm: '-5%', md: '-9%', lg: '0.5%' } }}>
                        <Typography sx={{ marginTop: { xs: '17%', sm: '8%', md: '4%', lg: '0%' } }}>
                            <Link to='/how-to' >
                                <ChevronLeft style={{ fontSize: '50px', color: '#707070', fontWeight: 'normal' }} />
                            </Link>
                        </Typography>
                        <Typography variant="h5" sx={{ marginTop: { xs: '20%', sm: '9%', md: '5%', lg: '5%' }, fontFamily: 'Inter', fontSize: '25px', fontWeight: "bold", fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.59, letterSpacing: 'normal', textAlign: 'left', color: '#000' }}>
                            <Link to='/how-to' style={{ textDecoration: 'none', color: '#000' }}>  How To   </Link>
                        </Typography>
                    </Grid>
                    <Grid sx={{ display: 'flex', marginLeft: { xs: '2%', sm: '2%', md: '2%', lg: '12.5%' }, width: '100%' }}>
                        <Typography variant="p" sx={{ fontFamily: 'Inter', fontSize: '14px', fontWeight: 500, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.94, letterSpacing: 'normal', color: '#9b9b9b' }}>
                            <Link to='/how-to' style={{ color: '#9b9b9b', textDecoration: 'none' }}> Home </Link>
                        </Typography>
                        <Typography variant="p" sx={{ paddingLeft: { xs: '2%', sm: '2%', md: '2%', lg: '1%' }, fontFamily: 'Inter', fontSize: '1px', color: '#9b9b9b', marginTop: { xs: '2%', sm: '1%', md: '1%', lg: '0.5%' } }}>
                            <Circle style={{ fontSize: '10px', color: '#9b9b9b', fontWeight: 'normal' }} />
                        </Typography>
                        <Typography variant="p" sx={{ paddingLeft: { xs: '2%', sm: '2%', md: '2%', lg: '1%' }, fontFamily: 'Inter', fontSize: '14px', fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.94, letterSpacing: 'normal', color: '#707070' }}>
                            How To
                        </Typography>
                        <Typography variant="p" sx={{ paddingLeft: { xs: '2%', sm: '2%', md: '2%', lg: '1%' }, fontFamily: 'Inter', fontSize: '1px', color: '#9b9b9b', marginTop: { xs: '2%', sm: '1%', md: '1%', lg: '0.5%' } }}>
                            <Circle style={{ fontSize: '10px', color: '#9b9b9b', fontWeight: 'normal' }} />
                        </Typography>
                        <Typography variant="p" sx={{ paddingLeft: { xs: '2%', sm: '2%', md: '2%', lg: '1%' }, fontFamily: 'Inter', fontSize: '14px', fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.94, letterSpacing: 'normal', color: '#dc3545' }}>
                            Sign In/Up
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ textAlign: 'left', alignContent: 'start', marginBottom: '1%' }}>
                        <Typography variant="h5" sx={{
                            display: 'flex', color: '#03541', fontFamily: 'Inter', fontSize: '27px', fontWeight: 600, textAlign: 'left', margin: '8px 942px 24px 217px', lineHeight: 1.38, letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
                        }}>
                            <img src={signInIcon} />  <Typography sx={{ display: 'flex', color: '#03541', fontFamily: 'Inter', fontSize: '20px', fontWeight: 'bold', textAlign: 'left', margin: '0 0 1px 11px', lineHeight: 1.38, letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal' }}>How to Sign In</Typography>
                        </Typography>
                        <Typography variant="body1" sx={{
                            color: '#707070', fontFamily: 'Inter', fontSize: '16px', fontWeight: 'normal', textAlign: 'left', margin: '16px 159px 10px 217px', lineHeight: 'normal', letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
                        }}>
                            ZiZi Customer have the option to Sign In and Sign Up on the portal using various methods which includes Google, Facebook, Mobile Number and Email. This Manual will provide step by step instructions on how to sign in and sign up using all the listed methods.
                        </Typography>
                        <Typography variant="h5" sx={{
                            color: '#010202', fontFamily: 'Inter', fontSize: '16px', fontWeight: 550, textAlign: 'left', margin: '25px 942px 24px 217px', lineHeight: 1.38, letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
                        }}>
                            <Circle style={{ width: '10px', color: '#cb444a', height: '10px', mb: 10, textTransform: 'capitalize' }} /> Sign In with Phone Number
                        </Typography>
                        <Typography variant="body1" sx={{
                            color: '#707070', fontFamily: 'Inter', fontSize: '16px', fontWeight: 'normal', textAlign: 'left', margin: '16px 159px 10px 217px', lineHeight: 'normal', letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
                        }}>
                            - Enter your phone number and PIN to access your account <br />
                            - In some cases like Invalid phone number, user not found or invalid email address, please enter your registered email address to reset login details. <br />
                            - Navigate to your email for reset instructions. <br />
                            - Use the PIN sent to your email to reset your account.
                        </Typography>
                        <Typography variant="h5" sx={{
                            color: '#010202', fontFamily: 'Inter', fontSize: '16px', fontWeight: 550, textAlign: 'left', margin: '25px 942px 24px 217px', lineHeight: 1.38, letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
                        }}>
                            <Circle style={{ width: '10px', color: '#cb444a', height: '10px', mb: 10 }} /> Sign In with Google
                        </Typography>
                        <Typography variant="body1" sx={{
                            color: '#707070', fontFamily: 'Inter', fontSize: '16px', fontWeight: 'normal', textAlign: 'left', margin: '16px 159px 10px 217px', lineHeight: 'normal', letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
                        }}>
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                        </Typography>
                        <Typography variant="h5" sx={{
                            color: '#010202', fontFamily: 'Inter', fontSize: '16px', fontWeight: 550, textAlign: 'left', margin: '25px 942px 24px 217px', lineHeight: 1.38, letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
                        }}>
                            <Circle style={{ width: '10px', color: '#cb444a', height: '10px', mb: 10 }} /> Sign In with Facebook
                        </Typography>
                        <Typography variant="body1" sx={{
                            color: '#707070', fontFamily: 'Inter', fontSize: '16px', fontWeight: 'normal', textAlign: 'left', margin: '16px 159px 10px 217px', lineHeight: 'normal', letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
                        }}>
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                        </Typography>
                        <Typography variant="h5" sx={{
                            color: '#010202', fontFamily: 'Inter', fontSize: '16px', fontWeight: 550, textAlign: 'left', margin: '25px 942px 24px 217px', lineHeight: 1.38, letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
                        }}>
                            <Circle style={{ width: '10px', color: '#cb444a', height: '10px', mb: 10 }} /> Sign In with Email
                        </Typography>
                        <Typography variant="body1" sx={{
                            color: '#707070', fontFamily: 'Inter', fontSize: '16px', fontWeight: 'normal', textAlign: 'left', margin: '16px 159px 10px 217px', lineHeight: 'normal', letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
                        }}>
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                        </Typography>

                        <Typography variant="h5" sx={{
                            display: 'flex', color: '#03541', fontFamily: 'Inter', fontSize: '27px', fontWeight: 600, textAlign: 'left', margin: '58px 942px 24px 217px', lineHeight: 1.38, letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
                        }}>
                            <img src={signInIcon} />  <Typography sx={{ display: 'flex', color: '#03541', fontFamily: 'Inter', fontSize: '20px', fontWeight: 'bold', textAlign: 'left', margin: '0 0 1px 11px', lineHeight: 1.38, letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal' }}>How to Sign Up</Typography>
                        </Typography>
                        <Typography variant="h5" sx={{
                            color: '#010202', fontFamily: 'Inter', fontSize: '16px', fontWeight: 550, textAlign: 'left', margin: '25px 942px 24px 217px', lineHeight: 1.38, letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
                        }}>
                            <Circle style={{ width: '10px', color: '#cb444a', height: '10px', mb: 10, textTransform: 'capitalize' }} /> Sign Up with Phone Number
                        </Typography>
                        <Typography variant="body1" sx={{
                            color: '#707070', fontFamily: 'Inter', fontSize: '16px', fontWeight: 'normal', textAlign: 'left', margin: '16px 159px 10px 217px', lineHeight: 'normal', letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
                        }}>
                           - Select and click on “sign up with Facebook” <br />
                           - Click on your Google account that is linked to Facebook. <br />
                           - Click the continue button on the pop up message. Continue as "Your Facebook name"? <br />
                           - After successful registration, please follow the instructions sent to your email for successful login. <br />
                           - Enter the pin sent to your email as the one time pin. <br />
                           - Your pin will be verified successfully. <br />
                           - Input your preferred pin for both security and memory purposes.
                        </Typography>
                        <Typography variant="h5" sx={{
                            color: '#010202', fontFamily: 'Inter', fontSize: '16px', fontWeight: 550, textAlign: 'left', margin: '25px 942px 24px 217px', lineHeight: 1.38, letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
                        }}>
                            <Circle style={{ width: '10px', color: '#cb444a', height: '10px', mb: 10 }} /> Sign Up with Google
                        </Typography>
                        <Typography variant="body1" sx={{
                            color: '#707070', fontFamily: 'Inter', fontSize: '16px', fontWeight: 'normal', textAlign: 'left', margin: '16px 159px 10px 217px', lineHeight: 'normal', letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
                        }}>
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                        </Typography>
                        <Typography variant="h5" sx={{
                            color: '#010202', fontFamily: 'Inter', fontSize: '16px', fontWeight: 550, textAlign: 'left', margin: '25px 942px 24px 217px', lineHeight: 1.38, letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
                        }}>
                            <Circle style={{ width: '10px', color: '#cb444a', height: '10px', mb: 10 }} /> Sign Up with Facebook
                        </Typography>
                        <Typography variant="body1" sx={{
                            color: '#707070', fontFamily: 'Inter', fontSize: '16px', fontWeight: 'normal', textAlign: 'left', margin: '16px 159px 10px 217px', lineHeight: 'normal', letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
                        }}>
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                        </Typography>
                        <Typography variant="h5" sx={{
                            color: '#010202', fontFamily: 'Inter', fontSize: '16px', fontWeight: 550, textAlign: 'left', margin: '25px 942px 24px 217px', lineHeight: 1.38, letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
                        }}>
                            <Circle style={{ width: '10px', color: '#cb444a', height: '10px', mb: 10 }} /> Sign Up with Email
                        </Typography>
                        <Typography variant="body1" sx={{
                            color: '#707070', fontFamily: 'Inter', fontSize: '16px', fontWeight: 'normal', textAlign: 'left', margin: '16px 159px 10px 217px', lineHeight: 'normal', letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
                        }}>
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                        </Typography>
                    </Grid>
                  
                </Grid>
            </Box>
        </>
    );
}

export default SignInOut;