import { createAsyncThunk } from "@reduxjs/toolkit";
import { HttpRequest } from "../components/HttpRequest";
import { createSlice } from "@reduxjs/toolkit";
import secureLocalStorage from "react-secure-storage";
export const phoneLoginUser = createAsyncThunk(
    "phone/login",
    async ({ phone, password }, thunkAPI) => {
        try {
            const requestParams = {
                "userEmailPhone": phone,
                "password": password,
            }
            const url = "login";
            const response = await HttpRequest({ url, requestParams });
            console.log("This is the response", response);
            let data = await response.data;
            if (data.status === "00") {
                secureLocalStorage.setItem("token", data.token);
                secureLocalStorage.setItem("user", JSON.stringify(data.user));
                return data;
            } else if (data.status === "19") {
                console.log("Need to change password response",data);
                secureLocalStorage.setItem("user", JSON.stringify(data));
                return thunkAPI.rejectWithValue(data);
            } else {
                console.log("Error dat", data);
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error) {
            console.log("Error", error.response.data);
            thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const PhoneLoginSlice = createSlice({
    name: "phoneLogin",
    initialState: {
        token: "",
        user: null,
        isFetching: false,
        isSuccess: false,
        isError: false,
        errorMessage: "",
        status:"",
    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isFetching = false;
            state.status="";
            return state;
        },
        checkLoginStatus: (state) => {
            const user = secureLocalStorage.getItem('user');
            if (user) {
                state.user = JSON.parse(user);
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(phoneLoginUser.fulfilled, (state, { payload }) => {
                state.token = payload.token;
                state.user = payload.user;
                state.isFetching = false;
                state.isSuccess = true;
                state.status = payload.status;
                return state;
            })
            .addCase(phoneLoginUser.rejected, (state, { payload }) => {
                state.isFetching = false;
                state.isError = true;
                state.errorMessage = payload.message;
                state.status = payload.status;
            })
            .addCase(phoneLoginUser.pending, (state) => {
                state.isFetching = true;
            });
    }
});

export const { clearState, checkLoginStatus } = PhoneLoginSlice.actions;

export const loginSelector = (state) => state.phoneLogin;