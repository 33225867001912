import { Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import loginImage from "../../../assets/login.svg";
import Snackbar from "@mui/material/Snackbar";
import { useEffect, useState } from "react";
import { Alert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import DrawerAppBar from "../../../components/navbar";
import { HttpRequest } from "../../../components/HttpRequest";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { resetPasswordSelector, clearState, resetPassword } from "../../../slices/resetPassword";

const ChangePassword = () => {
    const [open, setOpen] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [message, setMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const { isSuccess, isError, errorMessage } = useSelector(resetPasswordSelector);


    const handleLogin = async (e) => {
        e.preventDefault();
        var isValid = true;
        setOpen(false);
        setMessage("");

        if (oldPassword === "") {
            setOpen(true);
            setMessage("Please enter Old PIN.");
            isValid = false;
        }
        if (newPassword === "") {
            setOpen(true);
            setMessage("Please enter New PIN.");
            isValid = false;
        }
        if (passwordConfirm === "") {
            setOpen(true);
            setMessage("Please confirm PIN. ");
            isValid = false;
        }
        if (passwordConfirm !== newPassword) {
            setOpen(true);
            setMessage("New PIN and confirm PIN do not match. ");
            isValid = false;
        }
        if (isValid) {
            dispatch(resetPassword({oldPassword, newPassword}));
        }
    }

    useEffect(() => {
        return () => {
            dispatch(clearState())
        }
    }, [])
    useEffect(() => {
        if (isError) {
            setOpen(true);
            setMessage(errorMessage);
            dispatch(clearState());
        }
        if (isSuccess) {
            dispatch(clearState);
            navigate('/dashboard');
        }
    }, [isError, isSuccess]);
    const handleClose = (event, reason) => {
        setOpen(false);
        setOpenSuccess(false);
        setMessage("");
        setSuccessMessage("");
    };

    return (
        <>
            <DrawerAppBar />
            <Box component="main" sx={{ marginBottom: { xs: '10%', sm: '10%', md: '58%', lg: '5%' } }}>
                <Grid container={true} sx={{ marginTop: { xs: '40%', sm: '15%', md: '10%', lg: '5%' }, alignContent: 'center', width: '100%', height: '100%', textAlign: 'center' }} spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12} lg={6} sx={{ textAlign: 'center', alignContent: 'center' }}>
                        <Typography sx={{ marginLeft: { xs: '10%', sm: '10%', md: '18%', lg: '18%' }, objectFit: 'contain' }}>
                            <img src={loginImage} alt="" style={{ width: '70%' }} />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} sx={{ textAlign: 'center', alignContent: 'center' }}>
                        <Box sx={{ width: '100%', textAlign: 'center' }}>
                            <Typography variant="h4" sx={{ width: '100%', height: 'auto', padding: '0 20% 0 20%', whiteSpace: "pre-wrap", marginLeft:  { xs: '5%', sm: '2%', md: '2%', lg: '5%'}, marginTop: { xs: '5%', sm: '3%', md: '3%', lg: '2%' }, marginBottom: { xs: '6%', sm: '5%', md: '3%', lg: '1%' }, fontFamily: 'Inter', fontSize: '22px', fontWeight: 600, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.59, letterSpacing: 'normal', color: '#707070', textAlign: 'center' }}>
                                Reset PIN!
                            </Typography>
                            <Typography variant="h6" sx={{ width: { xs: '50%', sm: '50%', md: '30%', lg: '35%' }, height: 'auto', marginLeft:{ xs: '28%', sm: '35%', md: '39%', lg: '35%' }, marginBottom: { xs: '5%', sm: '3%', md: '2%', lg: '1%' }, fontFamily: 'Inter', fontSize: '18px', fontWeight: 500, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.94, letterSpacing: 'normal', textAlign: 'center', color: '#9b9b9b' }}>
                                Please enter current PIN and new PIN to reset.
                            </Typography>
                            <form onSubmit={handleLogin} autoComplete="off">
                                <Grid container={true} sx={{ marginLeft: { xs: '10%', sm: '30%', md: '26%', lg: '29%' } }}>
                                    <FormControl sx={{ width: {xs: '80%', sm: '60%', md: '50%', lg: '50%'}, marginRight: '1%', marginTop: {xs: '8%', sm: '5%', md: '3%', lg: '2%'} }} variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-password">Old PIN</InputLabel>
                                        <OutlinedInput onChange={e => setOldPassword(e.target.value)} value={oldPassword} placeholder="Enter your Old  PIN"
                                            id="outlined-adornment-password"
                                            type={showPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Password"
                                        />
                                    </FormControl>
                                    <FormControl sx={{ width: {xs: '80%', sm: '60%', md: '50%', lg: '50%'}, marginRight: '1%', marginTop: {xs: '8%', sm: '5%', md: '3%', lg: '5%'} }} variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-password">New PIN</InputLabel>
                                        <OutlinedInput onChange={e => setNewPassword(e.target.value)} value={newPassword} placeholder="Enter your New PIN"
                                            id="outlined-adornment-password"
                                            type={showPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Password"
                                        />
                                    </FormControl>
                                    <FormControl sx={{ width:{xs: '80%', sm: '60%', md: '50%', lg: '50%'}, marginRight: '1%', marginTop: {xs: '8%', sm: '5%', md: '3%', lg: '5%'}, marginBottom: '3%' }} variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-password">Confirm PIN</InputLabel>
                                        <OutlinedInput onChange={e => setPasswordConfirm(e.target.value)} value={passwordConfirm} placeholder="Confirm your PIN"
                                            id="outlined-adornment-password"
                                            type={showPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Password"
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid container={true} style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "center",
                                    marginTop: { xs: '15%', sm: '10%', md: '78%', lg: '78%' },
                                }}>
                                    <Grid item>
                                        <Button type="submit" variant="contained" sx={{
                                            textTransform: 'capitalize', width: '180px', height: '50px', margin: '15% 16px 0px 0', padding: '15px 43px', borderRadius: '5px', backgroundColor: 'rgba(3, 37, 65, 0.4)', '&:hover': {
                                                backgroundColor: 'rgba(3, 37, 65, 0.4)'
                                            }
                                        }}>Reset PIN </Button>
                                    </Grid>
                                </Grid>
                                <Snackbar open={open} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} onClose={handleClose}>
                                    <Alert
                                        onClose={handleClose}
                                        severity="error"
                                        variant="filled"
                                        sx={{ width: '100%' }}
                                    >
                                        {message}
                                    </Alert>
                                </Snackbar>
                                <Snackbar open={openSuccess} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} onClose={handleClose}>
                                    <Alert
                                        onClose={handleClose}
                                        severity="success"
                                        variant="filled"
                                        sx={{ width: '100%' }}
                                    >
                                        {successMessage}
                                    </Alert>
                                </Snackbar>

                            </form>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default ChangePassword;