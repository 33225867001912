import { createAsyncThunk } from "@reduxjs/toolkit";
import { HttpRequest } from "../components/HttpRequest";
import { createSlice } from "@reduxjs/toolkit";
import secureLocalStorage from "react-secure-storage";
export const updateProfile = createAsyncThunk(
    "profile/update",
    async ({ firstName,middleName,lastName,idNumber,phoneNumber}, thunkAPI) => {
        try {
            const requestParams = {
                "firstName": firstName,
                "middleName": middleName,
                "lastName":lastName,
                "idNumber":idNumber,
                "phoneNumber":phoneNumber
            }
            
            const url = "update-profile";
            const response = await HttpRequest({ url,requestParams });
            console.log("This is the response",response);
            let data = await response.data;
            if (data.status === "00") {
                secureLocalStorage.setItem("user",JSON.stringify(data.user));
                return data;
            }else{
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error) {
            console.log("Error",error.response.data);
            thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const UpdateProfileSlice = createSlice({
    name:"updateProfile",
    initialState:{
        token:"",
        user:null,
        isFetching:false,
        isSuccess:false,
        isError:false,
        errorMessage:""
    },
    reducers:{
        clearState:(state) => {
            state.isError=false;
            state.isSuccess = false;
            state.isFetching = false;

            return state;
        },
    },
    extraReducers:(builder)=>{
        builder
        .addCase(updateProfile.fulfilled,(state,{payload}) => {
            console.log("Payload new user",payload.user);
            state.token = payload.token;
            state.user=payload.user;
            state.isFetching=false;
            state.isSuccess=true;
            return state;
        })
        .addCase(updateProfile.rejected,(state,{payload})=>{
            state.isFetching=false;
            state.isError=true;
            state.errorMessage=payload.message
        })
        .addCase(updateProfile.pending,(state)=>{
            state.isFetching=true;
        });
    }
});

export const {clearState} = UpdateProfileSlice.actions;

export const profleSelector = (state) => state.updateProfile;