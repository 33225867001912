import { configureStore } from "@reduxjs/toolkit";
import { EmailLoginSlice } from "../slices/emailLogin";
import { PhoneLoginSlice } from "../slices/phoneLogin";
import { GoogleLoginSlice } from "../slices/googleLogin";
import { GoogleSignupSlice } from "../slices/googleSignup";
import { UpdateProfileSlice } from "../slices/updateProfile";
import { FacebookLoginSlice } from "../slices/facebookLogin";
import { FacebookSignupSlice } from "../slices/facebookSignup";
import { verifyPhoneNumberSlice } from "../slices/verifyPhoneNumber";
import { EmailSignupSlice } from "../slices/emailSignup";
import { ResetPasswordSlice } from "../slices/resetPassword";

const store = configureStore({
    reducer: {
        emailLogin: EmailLoginSlice.reducer,
        emailSignup: EmailSignupSlice.reducer,
        phoneLogin: PhoneLoginSlice.reducer,
        googleLogin: GoogleLoginSlice.reducer,
        googleSignup: GoogleSignupSlice.reducer,
        updateProfile: UpdateProfileSlice.reducer,
        facebookLogin: FacebookLoginSlice.reducer,
        facebookSignup: FacebookSignupSlice.reducer,
        verifyPhoneNumber: verifyPhoneNumberSlice.reducer,
        resetPassword:ResetPasswordSlice.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            seriazableCheck: false
        })
});

export default store;