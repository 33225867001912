import { Alert, Box, Button, FormControl, Grid, Snackbar, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import loginImage from "../../../assets/login.svg";
import DrawerAppBar from "../../../components/navbar";
import googleImage from "../../../assets/google-icn.svg";
import facebookImage from "../../../assets/facebook.svg";
import phoneImage from "../../../assets/mobile.svg";
import emailImage from "../../../assets/email-icn.svg";
import twitterImage from "../../../assets/twitter-icn.svg";
import { Link } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { googleSignupUser, signupSelector, clearState } from "../../../slices/googleSignup";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { facebookSignupUser, facebookSignupSelector, clearState as facebookClearState } from "../../../slices/facebookSignup";

const signupOptions = [
    {
        name: 'Phone',
        image: phoneImage,
        url: 'phone'
    },
    {
        name: 'Email',
        image: emailImage,
        url: 'email'
    }
];


const SignupIndex = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [user, setUser] = useState([]);
    const [facebookUser, setFacebookUser] = useState([]);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState(false);

    const { isSuccess, isError, errorMessage } = useSelector(signupSelector);
    const { facebookIsSuccess, facebookIsError, facebookErrorMessage } = useSelector(facebookSignupSelector);

    const signup = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });

    useEffect(() => {
        if (user) {
            axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                        Accept: 'application/json'
                    }
                })
                .then((res) => {
                    console.log('Google response data', res.data);
                    const email = res.data.email;
                    const provider_id = res.data.id;
                    dispatch(googleSignupUser({ email, provider_id }))
                })
                .catch((err) => console.log(err));
        }

    }, [user]);

    useEffect(() => {
        if (facebookUser) {
            const facebookEmail = facebookUser.email;
            const facebookId = facebookUser.id;
            facebookId && dispatch(facebookSignupUser({ facebookEmail, facebookId }))
        }
    }, [facebookUser]);


    useEffect(() => {
        return () => {
            dispatch(clearState());
        };
    }, []);
    useEffect(() => {
        return () => {
            dispatch(facebookClearState());
        };
    }, []);

    useEffect(() => {
        if (isError) {
            setOpen(true);
            setMessage(errorMessage);
            dispatch(clearState());
        }
        if (isSuccess) {
            dispatch(clearState());
            navigate('/profile/update');
        }
    }, [isError, isSuccess]);

    useEffect(() => {
        if (facebookIsError) {
            setOpen(true);
            setMessage(facebookErrorMessage);
            dispatch(facebookClearState());
        }
        if (facebookIsSuccess) {
            dispatch(facebookClearState());
            navigate('/profile/update');
        }
    }, [facebookIsError, facebookIsSuccess]);

    const handleClose = (event, reason) => {
        setOpen(false);
        setMessage("");
    };

    return (
        <>
            <DrawerAppBar />
            <Box component="main" sx={{ marginBottom: { xs: '10%', sm: '10%', md: '58%', lg: '5%' } }}>
                <Grid container={true} sx={{ marginTop: { xs: '80%', sm: '50%', md: '38%', lg: '18%' }, alignContent: 'center', width: '100%', height: '235px', textAlign: 'center' }} spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12} lg={6} sx={{ textAlign: 'center', alignContent: 'center' }}>
                        <Typography sx={{ marginLeft: { xs: '10%', sm: '10%', md: '18%', lg: '18%' }, objectFit: 'contain' }}>
                            <img src={loginImage} alt="" style={{ width: '70%' }} />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} sx={{ textAlign: 'center', alignContent: 'center' }}>
                        <Box sx={{ width: '100%', textAlign: 'center' }}>
                            <Typography variant="h4" sx={{ width: '75%', height: 'auto', padding: '0 20% 0 20%', whiteSpace: "pre-wrap", marginLeft: '10%', marginTop: { xs: '12%', sm: '10%', md: '5%', lg: '5%' }, marginBottom: { xs: '12%', sm: '10%', md: '8%', lg: '1%' }, fontFamily: 'Inter', fontSize: '22px', fontWeight: 600, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.59, letterSpacing: 'normal', color: '#272d3b', textAlign: 'center' }}>
                                Create Account!
                            </Typography>
                            <Typography variant="p" sx={{ width: '100%', height: 'auto', marginTop: { xs: '15%', sm: '10%', md: '8%', lg: '28%' }, marginBottom: { xs: '15%', sm: '10%', md: '8%', lg: '90%' }, fontFamily: 'Inter', fontSize: '18px', fontWeight: 500, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.94, letterSpacing: 'normal', textAlign: 'center', color: '#707070' }}>
                                Sign Up with your preferred method.
                            </Typography>
                            <Grid container={true} style={{
                                display: "flex",
                                width: "100%",
                            }}>
                                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: { xs: '15%', sm: '10%', md: '78%', lg: '3%' } }}>

                                    <Button variant="contained" onClick={() => signup()} sx={{
                                        textAlign: 'start', boxShadow: 'none', border: 'solid 1px #e0e0e0', width: '40%', height: '150%', borderRadius: '4px', backgroundColor: '#fff', color: '#000', '&:hover': {
                                            backgroundColor: '#fff', color: '#000', boxShadow: 'none'
                                        }
                                    }}>
                                        <img src={googleImage} alt="" style={{ marginRight: "10%" }} />
                                        <Typography style={{ marginRight: "60%", textTransform: 'capitalize', fontWeight: 'normal', color: '#707070' }}>
                                            Google
                                        </Typography>
                                    </Button>
                                </Grid>
                                <FacebookLogin
                                    appId="242789358826250"
                                    onSuccess={(response) => {
                                        console.log('Login Success!', response);
                                    }}
                                    onFail={(error) => {
                                        console.log('Login Failed!', error);
                                        setMessage("Facebook login failed. Please try again.");
                                    }}
                                    onProfileSuccess={(response) => {
                                        console.log('Get Profile Success!', response);
                                        setFacebookUser(response);
                                    }}
                                    render={({ onClick, logout }) => (
                                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: { xs: '15%', sm: '10%', md: '78%', lg: '3%' } }}>

                                            <Button onClick={onClick} onLogoutClick={logout} variant="contained" sx={{
                                                textAlign: 'start', boxShadow: 'none', border: 'solid 1px #e0e0e0', width: '40%', height: '150%', borderRadius: '4px', backgroundColor: '#fff', color: '#000', '&:hover': {
                                                    backgroundColor: '#fff', color: '#000', boxShadow: 'none'
                                                }
                                            }}>
                                                <img src={facebookImage} alt="" style={{ marginRight: "10%" }} />
                                                <Typography style={{ marginRight: "60%", textTransform: 'capitalize', fontWeight: 'normal', color: '#707070' }}>
                                                    Facebook
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    )}
                                />

          
                                {signupOptions.map((data, index) => (
                                    <Grid key={index} item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: { xs: '15%', sm: '10%', md: '78%', lg: '3%' } }}>
                                        <Link key={index} to={data.url} >
                                            <Button variant="contained" sx={{
                                                textAlign: 'start', boxShadow: 'none', border: 'solid 1px #e0e0e0', width: '40%', height: '150%', borderRadius: '4px', backgroundColor: '#fff', color: '#000', '&:hover': {
                                                    backgroundColor: '#fff', color: '#000', boxShadow: 'none'
                                                }
                                            }}>
                                                <img src={data.image} alt="" style={{ marginRight: "10%" }} />
                                                <Typography style={{ marginRight: "60%", textTransform: 'capitalize', fontWeight: 'normal', color: '#707070' }}>
                                                    {data.name}
                                                </Typography>
                                            </Button>
                                        </Link>
                                    </Grid>
                                ))}
                            </Grid>
                            <Grid style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: { xs: '15%', sm: '10%', md: '78%', lg: '98%' },
                                marginRight: "10%"
                            }}>
                                <Typography variant="p" style={{ marginTop: "5%", color: "#707070" }}>Already have an account? </Typography>
                                <Typography variant="p" style={{ marginTop: "5%", color: "#032541", marginLeft: '1%' }}><Link to="/sign-in" style={{ textDecoration: 'none', color: '#032541', fontSize: '16px', fontWeight: 'normal', fontFamily: 'Inter' }}>Login.</Link></Typography>
                            </Grid>
                            <Snackbar open={open} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} onClose={handleClose}>
                                <Alert
                                    onClose={handleClose}
                                    severity="error"
                                    variant="filled"
                                    sx={{ width: '100%' }}
                                >
                                    {message}
                                </Alert>
                            </Snackbar>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default SignupIndex;