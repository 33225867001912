import { Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import loginImage from "../../assets/login.svg";
import Snackbar from "@mui/material/Snackbar";
import { useEffect, useState } from "react";
import { Alert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { clearState,updateProfile,profleSelector } from "../../slices/updateProfile";
import { Navigate, useNavigate } from "react-router-dom";
import DrawerAppBar from "../../components/navbar";

const UpdateProfile = () => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState(false);
  

    const [firstName,setFirstName] = useState("");
    const [middleName,setMiddleName] = useState("");
    const [lastName,setLastName] = useState("");
    const [idNumber,setIdNumber] = useState("");
    const [phoneNumber,setPhoneNumber] = useState("");

    const dispatch = useDispatch();
    const navigate = useNavigate();

    
    const { isSuccess, isError, errorMessage } = useSelector(profleSelector);
    const handleLogin = async (e) => {
        e.preventDefault();
        var isValid = true;
        setOpen(false);
        setMessage("");
        if (firstName === "") {
            setOpen(true);
            setMessage("Please enter your First Name");
            isValid = false;
        }
        if (lastName === "") {
            setOpen(true);
            setMessage("Please enter your Last Name");
            isValid = false;
        }
        if (idNumber === "") {
            setOpen(true);
            setMessage("Please enter your ID Number");
            isValid = false;
        }
        if (phoneNumber === "") {
            setOpen(true);
            setMessage("Please enter your Phone Number");
            isValid = false;
        }
        if (isValid) {
            dispatch(updateProfile({ firstName,middleName,lastName,idNumber,phoneNumber }));
        }
    }

    useEffect(() => {
        return () => {
            dispatch(clearState());
        };
    }, []);

    useEffect(() => {
        if (isError) {
            setOpen(true);
            setMessage(errorMessage);
            dispatch(clearState());
        }
        if (isSuccess) {
            dispatch(clearState);
            navigate('/profile/verify-phone');
        }
    }, [isError, isSuccess]);
    const handleClose = (event, reason) => {
        setOpen(false);
        setMessage("");
    };

    return (
        <>
            <DrawerAppBar />
            <Box component="main" sx={{ marginBottom: { xs: '10%', sm: '10%', md: '58%', lg: '5%' } }}>
                <Grid container={true} sx={{ marginTop: { xs: '80%', sm: '50%', md: '38%', lg: '18%' }, alignContent: 'center', width: '100%', height: '235px', textAlign: 'center' }} spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12} lg={6} sx={{ textAlign: 'center', alignContent: 'center' }}>
                        <Typography sx={{ marginLeft: { xs: '10%', sm: '10%', md: '18%', lg: '18%' }, objectFit: 'contain' }}>
                            <img src={loginImage} alt="" style={{ width: '70%' }} />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} sx={{ textAlign: 'center', alignContent: 'center' }}>
                        <Box sx={{ width: '100%', textAlign: 'center' }}>
                            <Typography variant="h4" sx={{ width: '75%', height: 'auto', padding: '0 20% 0 20%', whiteSpace: "pre-wrap", marginLeft: '10%', marginTop: { xs: '12%', sm: '10%', md: '5%', lg: '2%' }, marginBottom: { xs: '12%', sm: '10%', md: '8%', lg: '2%' }, fontFamily: 'Inter', fontSize: '22px', fontWeight: 600, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.59, letterSpacing: 'normal', color: '#707070', textAlign: 'center' }}>
                                Update Profile!
                            </Typography>
                            <Typography variant="h6" sx={{ width: '30%', height: 'auto', marginLeft: { xs: '15%', sm: '10%', md: '8%', lg: '35%' }, marginBottom: { xs: '15%', sm: '10%', md: '8%', lg: '5%' }, fontFamily: 'Inter', fontSize: '18px', fontWeight: 500, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.94, letterSpacing: 'normal', textAlign: 'center', color: '#9b9b9b' }}>
                                Update the following information to complete the profile.
                            </Typography>
                            <form onSubmit={handleLogin} autoComplete="off">
                                <Grid container={true} sx={{ marginLeft: { xs: '10%', sm: '30%', md: '26%', lg: '29%' } }}>
                                    <FormControl sx={{ width: { xs: '75%', sm: '30%', md: '26%', lg: '50%' }, marginRight: '1%' }}>
                                        <TextField onChange={e => setFirstName(e.target.value)} value={firstName} placeholder="Enter Your First" color="grey" label='First Name' variant="outlined" sx={{ marginRight: '1%', backgroundColor: '#fff' }} id="firstName" />
                                    </FormControl>
                                    <FormControl sx={{ width: { xs: '75%', sm: '30%', md: '26%', lg: '50%' }, marginRight: '1%',marginTop:'2%' }}>
                                        <TextField onChange={e => setMiddleName(e.target.value)} value={middleName} placeholder="Enter Your Middle Name (Optional)" color="grey" label='Middle Name' variant="outlined" sx={{ marginRight: '1%', backgroundColor: '#fff' }} id="middleName" />
                                    </FormControl>
                                </Grid>
                                <Grid container={true} sx={{ marginLeft: { xs: '10%', sm: '30%', md: '26%', lg: '29%' } }}>
                                    <FormControl sx={{ width: { xs: '75%', sm: '30%', md: '26%', lg: '50%' }, marginRight: '1%',marginTop:'2%' }}>
                                        <TextField onChange={e => setLastName(e.target.value)} value={lastName} placeholder="Enter Last Name" color="grey" label='Last Name' variant="outlined" sx={{ marginRight: '1%', backgroundColor: '#fff' }} id="lastName" />
                                    </FormControl>
                                    <FormControl sx={{ width: { xs: '75%', sm: '30%', md: '26%', lg: '50%' }, marginRight: '1%',marginTop:'2%' }}>
                                        <TextField onChange={e => setIdNumber(e.target.value)} value={idNumber} placeholder="Enter Your ID Number" color="grey" label='ID Number' variant="outlined" sx={{ marginRight: '1%', backgroundColor: '#fff' }} id="idNumber" />
                                    </FormControl>
                                </Grid>
                                <Grid container={true} sx={{ marginLeft: { xs: '10%', sm: '30%', md: '26%', lg: '29%' } }}>
                                    <FormControl sx={{ width: { xs: '75%', sm: '30%', md: '26%', lg: '50%' }, marginRight: '1%',marginTop:'2%' }}>
                                        <TextField onChange={e => setPhoneNumber(e.target.value)} value={phoneNumber} placeholder="Enter Your Phone Number" color="grey" label='Phone Number' variant="outlined" sx={{ marginRight: '1%', backgroundColor: '#fff' }} id="phoneNumber" />
                                    </FormControl>
                                </Grid>
                                <Grid style={{
                                    display: "flex",
                                    justifyContent: "right",
                                    marginTop: { xs: '15%', sm: '10%', md: '78%', lg: '78%' },
                                    marginRight: "22%"
                                }}>
                                    <Typography variant="p" style={{ marginTop: "1%", color: "#03254" }}>Forgot Pin?</Typography>
                                </Grid>
                                <Grid container={true} style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "center",
                                    marginTop: { xs: '15%', sm: '10%', md: '78%', lg: '78%' },
                                }}>
                                    <Grid item>
                                        <Button type="submit" variant="contained" sx={{
                                            textTransform: 'capitalize', width: '150px', height: '50px', margin: '15% 16px 0px 0', padding: '15px 43px', borderRadius: '5px', backgroundColor: 'rgba(3, 37, 65, 0.4)', '&:hover': {
                                                backgroundColor: 'rgba(3, 37, 65, 0.4)'
                                            }
                                        }}>Update</Button>
                                    </Grid>
                                </Grid>
                                <Snackbar open={open} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} onClose={handleClose}>
                                    <Alert
                                        onClose={handleClose}
                                        severity="error"
                                        variant="filled"
                                        sx={{ width: '100%' }}
                                    >
                                        {message}
                                    </Alert>
                                </Snackbar>

                            </form>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default UpdateProfile;