import DrawerAppBar from "../../components/navbar";
import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { ChevronLeft, Circle, Facebook, Instagram, Language, LinkedIn, Twitter } from "@mui/icons-material";
import { Link } from "react-router-dom";
import vetIcon from "../../assets/news/vet-lg.png";
import callIcon from "../../assets/phone-sm.svg";
import emailIcon from "../../assets/mail-sm.svg";
import locationIcon from "../../assets/location-sm.svg";

const FreeVetCheckupNews = () => {
    return (
        <>
            <DrawerAppBar />
            <Box component="main" sx={{ marginBottom: { xs: '10%', sm: '10%', md: '58%', lg: '6%' }, marginTop: { xs: '100%', sm: '50%', md: '45%', lg: '6%' } }}>
                <Grid container={true} sx={{ marginTop: { xs: '100%', sm: '20%', md: '8%', lg: '2%' }, alignContent: 'center', width: '100%', height: '100%', textAlign: 'center' }} spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item sx={{ display: 'flex', ml: { xs: '10%', sm: '30%', md: '25%', lg: '10.5%' }, marginBottom: { xs: '10%', sm: '-5%', md: '-9%', lg: '0.5%' } }}>
                        <Typography sx={{ marginTop: { xs: '17%', sm: '8%', md: '4%', lg: '0%' } }}>
                            <Link to='/news' >
                                <ChevronLeft style={{ fontSize: '50px', color: '#707070', fontWeight: 'normal' }} />
                            </Link>
                        </Typography>
                        <Typography variant="h5" sx={{ marginTop: { xs: '20%', sm: '9%', md: '5%', lg: '2%' }, fontFamily: 'Inter', fontSize: '25px', fontWeight: "bold", fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.59, letterSpacing: 'normal', textAlign: 'left', color: '#000' }}>
                            <Link to='/news' style={{ textDecoration: 'none', color: '#000' }}>  Free Vet Checkup   </Link>
                        </Typography>
                    </Grid>
                    <Grid sx={{ display: 'flex', marginLeft: { xs: '2%', sm: '2%', md: '2%', lg: '12.5%' }, width: '100%' }}>
                        <Typography variant="p" sx={{ fontFamily: 'Inter', fontSize: '14px', fontWeight: 500, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.94, letterSpacing: 'normal', color: '#9b9b9b' }}>
                            <Link to='/news' style={{ color: '#9b9b9b', textDecoration: 'none' }}> Home </Link>
                        </Typography>
                        <Typography variant="p" sx={{ paddingLeft: { xs: '2%', sm: '2%', md: '2%', lg: '1%' }, fontFamily: 'Inter', fontSize: '1px', color: '#9b9b9b', marginTop: { xs: '2%', sm: '1%', md: '1%', lg: '0.5%' } }}>
                            <Circle style={{ fontSize: '10px', color: '#9b9b9b', fontWeight: 'normal' }} />
                        </Typography>
                        <Typography variant="p" sx={{ paddingLeft: { xs: '2%', sm: '2%', md: '2%', lg: '1%' }, fontFamily: 'Inter', fontSize: '14px', fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.94, letterSpacing: 'normal', color: '#707070' }}>
                        Free Vet Checkup
                        </Typography>

                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={9} sx={{ textAlign: 'left', alignContent: 'start', marginBottom: '1%' }}>
                        <Typography variant="h5" sx={{
                            display: 'flex', color: '#03541', fontFamily: 'Inter', fontSize: '27px', fontWeight: 600, textAlign: 'left', margin: '8px 942px 24px 217px', lineHeight: 1.38, letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
                        }}>
                            <Typography sx={{ display: 'flex', color: '#03541', fontFamily: 'Inter', fontSize: '29px', fontWeight: 550, textAlign: 'left', margin: '0 0 1px 11px', lineHeight: 1.67, letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal' }}> Free Vet Checkup
                               </Typography>
                        </Typography>
                        <Typography variant="body1" sx={{
                            color: '#707070', fontFamily: 'Inter', fontSize: '16px', fontWeight: 'normal', textAlign: 'left', margin: '16px 159px 10px 217px', lineHeight: 'normal', letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
                        }}>
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
                        </Typography>
                        <Typography variant="body1" sx={{
                            color: '#707070', fontFamily: 'Inter', fontSize: '16px', fontWeight: 'normal', textAlign: 'left', margin: '16px 159px 10px 217px', lineHeight: 'normal', letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
                        }}>
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
                        </Typography>
                        <Typography variant="body1" sx={{
                            color: '#707070', fontFamily: 'Inter', fontSize: '16px', fontWeight: 'normal', textAlign: 'left', margin: '16px 159px 10px 217px', lineHeight: 'normal', letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
                        }}>
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
                        </Typography>
                        <Typography variant="body1" sx={{
                            color: '#707070', fontFamily: 'Inter', fontSize: '16px', fontWeight: 'normal', textAlign: 'left', margin: '16px 159px 10px 217px', lineHeight: 'normal', letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
                        }}>
                           Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
                        </Typography>
                        <Typography variant="body1" sx={{
                            color: '#707070', fontFamily: 'Inter', fontSize: '16px', fontWeight: 'normal', textAlign: 'left', margin: '16px 159px 10px 217px', lineHeight: 'normal', letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
                        }}>
                           Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
                           Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
                        </Typography>

                        <Typography sx={{ fontFamily: 'Inter', margin: '156px 423px 30px 217px', fontSize: '20px', fontWeight: 'bold', lineHeight: 1, textAlign: 'left', color: '#707070' }}>
                            Get In Touch  with Us.
                        </Typography>
                        <Grid item lg={12} sx={{ display: 'flex', marginLeft: '15%' }}>
                            <Typography sx={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: 'normal', color: '#010202', textAlign: 'left', lineHeight: 1.43, marginRight: '1%' }}>
                                <img src={callIcon} alt="" />
                            </Typography>

                            <Typography sx={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: 'normal', color: '#010202', textAlign: 'left', lineHeight: 1.43, marginRight: '5%' }}>
                                (+254) 7 09 836-000
                            </Typography>
                            <Typography sx={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: 'normal', color: '#010202', textAlign: 'left', lineHeight: 1.43, marginRight: '1%' }}>
                                <img src={emailIcon} alt="" />
                            </Typography>
                            <Typography sx={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: 'normal', color: '#010202', textAlign: 'left', lineHeight: 1.43, marginRight: '5%' }}>
                                info@riverbank.co.ke
                            </Typography>
                            <Typography sx={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: 'normal', color: '#010202', textAlign: 'left', lineHeight: 1.43, marginRight: '1%' }}>
                                <img src={locationIcon} alt="" />
                            </Typography>
                            <Typography sx={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: 'normal', color: '#010202', textAlign: 'left', lineHeight: 1.43, marginRight: '5%' }}>
                                Suite B31, Silverpool Office suites, <br />
                                Jabavu Lane,Hurlingham.

                            </Typography>

                        </Grid>

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={3} sx={{ textAlign: 'left', alignContent: 'start', float: 'left' }}>
                        <Typography sx={{}}>
                            <img src={vetIcon} alt="" style={{ width: '479px', marginLeft: '-30%' }} />
                        </Typography>
                        <Typography sx={{ marginTop: '30%',marginLeft:'50%' }}>
                            <Facebook sx={{ color: '#1977f3', marginTop: '2.5%', marginRight: '1%', display: 'block' }} />
                            <Instagram sx={{ color: 'red', marginTop: '5%', marginRight: '1%', display: 'block' }} />
                            <LinkedIn sx={{ color: '#0070a8', marginTop: '5%', marginRight: '1%', display: 'block' }} />
                            <Twitter sx={{ color: '#1da1f2', marginTop: '5%', marginRight: '1%', display: 'block' }} />
                            <Language sx={{ marginTop: '5%', marginRight: '1%', display: 'block' }} />
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default FreeVetCheckupNews;