import { Box, Container, Paper, Tabs, Typography } from "@mui/material";
import { TabsList } from "@mui/base/TabsList";
import DashboardNavbar from "../../components/dashboardNavbar";
import { useEffect, useState } from "react";
import { HttpRequest } from "../../components/HttpRequest";
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarExport,gridClasses } from "@mui/x-data-grid";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';


function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport sx={{ color: '#707070' }} />
        </GridToolbarContainer>
    );
}


const Invoices = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [transactions, setTransactions] = useState([]);
    const [sbpInvoices, setSbpInvoices] = useState([]);
    const [transactionsLoading, setTransactionsLoading] = useState(true);
    const [sbpInvoicesLoading, setSbpInvoicesLoading] = useState(true);
    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const columns = [
        { field: 'invoiceNo', headerName: 'Invoice No.', width: 120 },
        { field: 'businessName', headerName: 'Business Name', width: 220 },
        { field: 'county', headerName: 'County', width: 320 },
        {
            field: 'subCounty',
            headerName: 'Subcounty',
            width: 220,
        },
        {
            field: 'category',
            headerName: 'Category',
            width: 200,
        },
        {
            field: 'amount',
            headerName: 'Amount',
            width: 90,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 100,
            valueGetter: (params) => {
                if (params.row.status === "0") {
                    return "Unpaid";
                }
                return "Paid"
            },
            cellClassName: (params) => {
                console.log("Status of values", params);
                if (params.row.status === "0") {
                    return "danger";
                }
                return "success"
            }
        },
        {
            field: 'dateCreated',
            headerName: 'Date Created',
            width: 160,
        },
    ];
    const sbpColumns = [
        { field: 'invoiceNo', headerName: 'Invoice No.', width: 120 },
        { field: 'businessName', headerName: 'Business Name', width: 120 },
        { field: 'county', headerName: 'County', width: 220 },
        {
            field: 'subCounty',
            headerName: 'Subcounty',
            width: 160,
        },
        {
            field: 'category',
            headerName: 'Category',
            width: 160,
        },
        {
            field: 'service',
            headerName: 'Service',
            width: 200,
        },
        {
            field: 'amount',
            headerName: 'Amount',
            width: 90,
        },
        {
            field: 'yearPaidFor',
            headerName: 'Year Paid For',
            width: 90,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 100,
            valueGetter: (params) => {
                if (params.row.status === "0") {
                    return "Unpaid";
                }
                return "Paid"
            },
            cellClassName: (params) => {
                console.log("Status of values", params);
                if (params.row.status === "0") {
                    return "danger";
                }
                return "success"
            }

        },
        {
            field: 'dateCreated',
            headerName: 'Date Created',
            width: 160,
        },
    ];
    const requestParams = {

    }
    useEffect(() => {
        setTransactionsLoading(true);
       const url = "get-invoices";
        HttpRequest({ url, requestParams })
            .then((result) => {
                console.log('Results', result.data.status);
                if (result.data.status === "00") {
                    console.log('Results inside', result);
                    setTransactions(result.data.invoices)
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setTransactionsLoading(false);
            });
    }, []);

    useEffect(() => {
        setSbpInvoicesLoading(true);
        const url = "get-sbp-invoices";
        HttpRequest({ url, requestParams })
            .then((result) => {
                console.log('SBP Results', result.data.status);
                if (result.data.status === "00") {
                    console.log('DSBP Results inside', result.data);
                    setSbpInvoices(result.data.invoices)
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setSbpInvoicesLoading(false);
            });
    }, []);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Box sx={{ display: 'flex', backgroundColor: '#F6F7F8', fontFamily: 'Inter' }} >
            <DashboardNavbar />
            <Container sx={{ marginTop: '5%', marginBottom: 4, marginLeft: '-2.3%' }}>
                <Paper sx={{ width: '142%', overflow: 'hidden', marginTop: '1%', padding: '2%' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="License Invoices" value="1" />
                                <Tab label="SBP Invoices" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <Typography variant="h6" sx={{
                                fontFamily: 'Inter',
                                fontSize: '16px',
                                fontWeight: 550,
                                color: '#010202',
                                fontStretch: 'normal',
                                fontStyle: 'normal',
                                lineHeight: 2.06,
                                letterSpacing: 'normal',
                                textAlign: 'left',
                                margin: '2% 988px 25px 0'
                            }}>
                                License Invoices
                            </Typography>

                            <div style={{ height: 400, width: '100%' }}>
                                <DataGrid sx={{
                                    [`.${gridClasses.cell}.success`]: {
                                        color: '#388E3C'
                                    },
                                    [`.${gridClasses.cell}.danger`]: {
                                        color: '#D32F2F',
                                    },
                                }}
                                    rows={transactions}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 5 },
                                        },
                                    }}
                                    pageSizeOptions={[5, 10]}
                                    checkboxSelection
                                    slots={{ toolbar: CustomToolbar }}
                                    loading={transactionsLoading}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value="2">
                            <Typography variant="h6" sx={{
                                fontFamily: 'Inter',
                                fontSize: '16px',
                                fontWeight: 550,
                                color: '#010202',
                                fontStretch: 'normal',
                                fontStyle: 'normal',
                                lineHeight: 2.06,
                                letterSpacing: 'normal',
                                textAlign: 'left',
                                margin: '2% 988px 25px 0'
                            }}>
                                SBP Invoices
                            </Typography>

                            <div style={{ height: 400, width: '100%' }}>
                                <DataGrid sx={{
                                    [`.${gridClasses.cell}.success`]: {
                                        color: '#388E3C'
                                    },
                                    [`.${gridClasses.cell}.danger`]: {
                                        color: '#D32F2F',
                                    },
                                }}
                                    rows={sbpInvoices}
                                    columns={sbpColumns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 5 },
                                        },
                                    }}
                                    pageSizeOptions={[5, 10]}
                                    checkboxSelection
                                    slots={{ toolbar: CustomToolbar }}
                                    loading={sbpInvoicesLoading}
                                />
                            </div>
                        </TabPanel>
                    </TabContext>
                </Paper>
            </Container>
        </Box >
    );
}

export default Invoices;