import { useState, useEffect } from 'react';
import { HttpRequest } from '../../../components/HttpRequest';

const ServiceTypes = (duration,serviceId) => {
    const [serviceTypes, setServiceTypes] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (duration !== "" && serviceId!=null) {
            const fetchServiceTypes = async () => {
                try {
                    const requestParams = {
                        "duration":duration,
                        "categoryId":serviceId
                    }
                    const url="get-services-unstructured";
                    const response = await HttpRequest({url,requestParams});
                    if (response.data.status === "00") {
                        setServiceTypes(response.data.services);
                        setLoading(false);
                    }
                } catch (error) {
                    console.error('Error fetching service types', error);
                }
            };

            fetchServiceTypes();
        }

    }, [duration,serviceId]);

    return { serviceTypes, loading };
};

export default ServiceTypes;