import { useState, useEffect } from 'react';
import { HttpRequest } from '../../../components/HttpRequest';

const Zones = () => {

    const [zones, setZones] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url="get-zones2";
                const response = await HttpRequest({url});
                if(response.data.status==="00")
                {
                    setZones(response.data.zones);
                    setLoading(false);
                }
            } catch (error) {
                console.error("Error fetching zones:", error);
            }
        };
        fetchData();
    }, []);

    return { zones, loading };
};

export default Zones;
