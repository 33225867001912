import { createAsyncThunk } from "@reduxjs/toolkit";
import { HttpRequest } from "../components/HttpRequest";
import { createSlice } from "@reduxjs/toolkit";
import secureLocalStorage from "react-secure-storage";
export const googleLoginUser = createAsyncThunk(
    "google/login",
    async ({ email,provider_id}, thunkAPI) => {
        try {
            console.log("Provider id in request",provider_id)
            const requestParams = {
                "userEmailPhone": email,
                "authProvider":"Google",
                "authProviderId":provider_id
            }
            const url = "login";
            const response = await HttpRequest({ url,requestParams });
            console.log("This is the response",response);
            let data = await response.data;
            if (data.status === "00") {
                secureLocalStorage.setItem("token", data.token);
                secureLocalStorage.setItem("user",JSON.stringify(data.user));
                return data;
            }else{
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error) {
            console.log("Error",error.response.data);
            thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const GoogleLoginSlice = createSlice({
    name:"googleLogin",
    initialState:{
        token:"",
        user:null,
        isFetching:false,
        isSuccess:false,
        isError:false,
        errorMessage:""
    },
    reducers:{
        clearState:(state) => {
            state.isError=false;
            state.isSuccess = false;
            state.isFetching = false;

            return state;
        },
        checkLoginStatus: (state) => {
            const user = localStorage.getItem('user');
            if (user) {
                state.user = JSON.parse(user);
            }
       },
    },
    extraReducers:(builder)=>{
        builder
        .addCase(googleLoginUser.fulfilled,(state,{payload}) => {
            console.log("Payload new user",payload.user);
            state.token = payload.token;
            state.user=payload.user;
            state.isFetching=false;
            state.isSuccess=true;
            return state;
        })
        .addCase(googleLoginUser.rejected,(state,{payload})=>{
            console.log("Payload messsage",payload);
            state.isFetching=false;
            state.isError=true;
            state.errorMessage=payload.message
        })
        .addCase(googleLoginUser.pending,(state)=>{
            state.isFetching=true;
        });
    }
});

export const {clearState,checkLoginStatus} = GoogleLoginSlice.actions;

export const loginSelector = (state) => state.googleLogin;