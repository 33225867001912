import { Grid } from "@mui/material";
import ServicesCarousel from "../components/servicesCarousel";
import Hero from "../components/hero";
import DrawerAppBar from "../components/navbar";


const Home = () => {
    return (
        <Grid container spacing={2}>
            <DrawerAppBar />
            <Grid item xs={12} sm={12} sx={{width:'100%'}}>
                <Hero />
            </Grid>
            <Grid item xs={12} sm={12} sx={{width:'100%',marginTop:{xs:'100%',sm:'100%',md:'0.5%',lg:'0.5%'}}}>
                <ServicesCarousel />
            </Grid>
        </Grid>
    );
}
export default Home;