import { createAsyncThunk } from "@reduxjs/toolkit";
import { HttpRequest } from "../components/HttpRequest";
import { createSlice } from "@reduxjs/toolkit";
import secureLocalStorage from "react-secure-storage";
export const facebookSignupUser = createAsyncThunk(
    "facebook/signup",
    async ({ facebookEmail,facebookId}, thunkAPI) => {
        try {
            console.log("Provider id in request",facebookId)
            const requestParams = {
                "email":facebookEmail,
                "authProvider":"Facebook",
                "authProviderId":facebookId,
                "phoneNo":"",
            }
            const url = "sign-up";
            const response = await HttpRequest({ url,requestParams });
            console.log("This is the response",response);
            let data = await response.data;
            if (data.status === "00") {
                secureLocalStorage.setItem("token", data.token);
                secureLocalStorage.setItem("user",JSON.stringify(data.user));
                return data;
            }else{
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error) {
            console.log("Error",error.response.data);
            thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const FacebookSignupSlice = createSlice({
    name:"facebookSignup",
    initialState:{
        token:"",
        user:null,
        facebookIsFetching:false,
        facebookIsSuccess:false,
        facebookIsError:false,
        facebookErrorMessage:""
    },
    reducers:{
        clearState:(state) => {
            state.facebookIsError=false;
            state.facebookIsSuccess = false;
            state.facebookIsFetching = false;

            return state;
        }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(facebookSignupUser.fulfilled,(state,{payload}) => {
            console.log("Payload new user",payload.user);
            state.token = payload.token;
            state.user=payload.user;
            state.facebookIsFetching=false;
            state.facebookIsSuccess=true;
            return state;
        })
        .addCase(facebookSignupUser.rejected,(state,{payload})=>{
            console.log("Payload messsage",payload);
            state.facebookIsFetching=false;
            state.facebookIsError=true;
            state.facebookErrorMessage=payload.message
        })
        .addCase(facebookSignupUser.pending,(state)=>{
            state.facebookIsFetching=true;
        });
    }
});

export const {clearState} = FacebookSignupSlice.actions;

export const facebookSignupSelector = (state) => state.facebookSignup;