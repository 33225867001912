import Swal from "sweetalert2";
const PaymentFailed = (message) => {
    return Swal.fire({
        icon: 'error',
        title: 'Payment Failed',
        text: message,
        showConfirmButton:false,
        showCancelButton: false,
        customClass:{
            content:'swal-content',
            title:'swal-title',
            text:'swal-text',
        }
    });
}

export default PaymentFailed;