import { Box, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import DrawerAppBar from "../components/navbar";
import landRatesImage from "../assets/news/land-rates.png";
import advertisingImage from "../assets/news/advertising.svg";
import vetImage from "../assets/news/vet.png";
import { Link } from "react-router-dom";

const Items = [
  {
    title: 'Land Rates Waiver',
    description: '“The waiver on interest and penalties for land rates has been extended until May 1st, 2024…….',
    image: landRatesImage,
    url:'/news/land-rates-waiver'
  },
  {
    title: 'Free Advertising',
    description: '“Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut….',
    image: advertisingImage,
    url:'/news/free-advertising'
  },
  {
    title: 'Free Vet Checkup',
    description: '“Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut….',
    image: vetImage,
    url:'/news/free-vet-checkup'
  }
];
const News = () => {
  return (
    <Box component="main" >
      <DrawerAppBar />
      <Grid container={true} sx={{ alignContent: 'center', textAlign: 'center', justifyContent: 'center' }} spacing={2} >
        <Grid container sx={{ paddingLeft: '15%', marginTop: '8%' }} spacing={2}>
          {Items.map((data, index) => (
            <Grid key={index} item lg={3} sx={{ width: '350px', height: '540px', margin: '34px 10px 0 10px', padding: '108px 37px 40px', borderRadius: '10px', boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)', backgroundColor: '#fff' }}>

              <img src={data.image} alt="" style={{ marginTop: '1%', paddingTop: '10%', maxWidth: '214px', maxHeight: '172px' }} />
              <Typography sx={{ margin: '10% 51px 24px 53px', fontFamily: 'Inter', fontSize: '18px', fontWeight: 550, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.44, letterSpacing: 'normal', textAlign: 'center', color: '#010202' }}>
                {data.title}
              </Typography>
              <Typography sx={{ margin: '24px 0 0', fontFamily: 'Inter', fontSize: '14px', fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.71, letterSpacing: 'normal', textAlign: 'center', color: '#8d97a2' }}>
                {data.description}
              </Typography>
              <Link to={data.url} style={{ textDecoration: 'none' }}>
                <Button variant="outline" sx={{width:'130px',height:'50px',margin:'35px 67px 0',padding:'15px 21px',borderRadius:'4px',border:'solid 1px #010202',color:'#010202','&:hover':{
                  color:'#fff',
                  backgroundColor:'#dc3545'
                }}}>Read More</Button>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box >
  );
}
export default News;