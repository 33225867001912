import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Home from "../pages/home";
import DrawerAppBar from "../components/navbar";
import Services from "../pages/services";
import Contacts from "../pages/contacts";
import FAQS from "../pages/faqs";
import News from "../pages/news";
import VetFees from "../pages/services/vetFees";
import Markets from "../pages/services/markets";
import Parking from "../pages/services/parking";
import Cess from "../pages/services/cess";
import LoginIndex from "../pages/auth/login";
import SignupIndex from "../pages/auth/signup";
import EmailLogin from "../pages/auth/login/options/email";
import DashboardIndex from "../pages/dashboard";
import PrivateRoute from "./privateRoute";
import GuestRoute from "./guestRoute";
import PhoneLogin from "../pages/auth/login/options/phone";
import EmailSignup from "../pages/auth/signup/options/email";
import { GoogleLogin } from "@react-oauth/google";
import UpdateProfile from "../pages/auth/updateProfile";
import VerifyPhone from "../pages/auth/verifyPhone";
import ForgotPassword from "../pages/auth/password/forgotPassword";
import ChangePassword from "../pages/auth/password/changePassword";
import VetService from "../pages/dashboard/services/vetFees";
import MarketService from "../pages/dashboard/services/markets";
import ParkingService from "../pages/dashboard/services/parking";
import CessService from "../pages/dashboard/services/cess";
import AdvertisingService from "../pages/dashboard/services/advertising";
import PsvService from "../pages/dashboard/services/psv";
import LicenseService from "../pages/dashboard/services/license";
import LandRatesService from "../pages/dashboard/services/landRates";
import SbpService from "../pages/dashboard/services/sbp";
import RentalsService from "../pages/dashboard/services/rentals";
import SignInOut from "../pages/howTo/signInOut";
import HowTo from "../pages/howTo";
import NewsDetails from "../pages/newsDetails";
import LandRatesNews from "../pages/news/landRates";
import FreeAdvertisingNews from "../pages/news/freeAdvertising";
import FreeVetCheckupNews from "../pages/news/freeVet";
import Transactions from "../pages/dashboard/transactions";
import Businesses from "../pages/dashboard/businesses";
import Invoices from "../pages/dashboard/invoices";
import Vehicles from "../pages/dashboard/vehicles";
import Billboards from "../pages/dashboard/billboards";
export default function AppRoutes() {
    

    return (
        <Routes>
      
            {/* Homepage Routes */}
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/how-to" element={<HowTo />} />
            <Route path="/faqs" element={<FAQS />} />
            <Route path="/news" element={<News />} />
            <Route path="/news/land-rates-waiver" element={<LandRatesNews />} />
            <Route path="/news/free-advertising" element={<FreeAdvertisingNews />} />
            <Route path="/news/free-vet-checkup" element={<FreeVetCheckupNews />} />

            {/* Unstructured Services Routes */}
            <Route path="/vetfees" element={<VetFees />} />
            <Route path="/markets" element={<Markets />} />
            <Route path="/parking" element={<Parking />} />
            <Route path="/cess" element={<Cess />} />

            <Route path="/how-to/sign-in-out" element={<SignInOut />}/>

            <Route element={<GuestRoute />}>
                <Route path="/sign-in" element={<LoginIndex />} />
                <Route path="/sign-in/email" element={<EmailLogin />} />
                <Route path="/sign-in/phone" element={<PhoneLogin />} />

                <Route path="/sign-up" element={<SignupIndex />} />
                <Route path="/sign-up/email" element={<EmailSignup />} />

                <Route path="/sign-in/google" element={<GoogleLogin />}/>

                <Route path="/password/forgot" element={<ForgotPassword />} />

                <Route path="/password/change" element={<ChangePassword />} />
            </Route>

            <Route element={<PrivateRoute />}>
                <Route path="/dashboard" element={<DashboardIndex />} />
                <Route path="/profile/update" element={<UpdateProfile />} />
                <Route path="/profile/verify-phone" element={<VerifyPhone />} />

                <Route path="/user/vetfees" element={<VetService />} />
                <Route path="/user/markets" element={<MarketService />} />
                <Route path="/user/parking" element={<ParkingService />} />
                <Route path="/user/cess" element={<CessService />} />
                <Route path="/user/advertising" element={<AdvertisingService />} />
                <Route path="/user/psv-stickers" element={<PsvService />} />
                <Route path="/user/license" element={<LicenseService />} />
                <Route path="/user/land-rates" element={<LandRatesService />} />
                <Route path="/user/sbp" element={<SbpService />} />
                <Route path="/user/rentals" element={<RentalsService />} />

                <Route path="/user/transactions" element={<Transactions />} />
                <Route path="/user/businesses" element={<Businesses />} />
                <Route path="/user/invoices" element={<Invoices />} />
                <Route path="/user/vehicles" element={<Vehicles />} />
                <Route path="/user/billboards" element={<Billboards />} />


            </Route>
        </Routes>
    );
}
