import Swal from "sweetalert2";
const ConfirmPaymentAlert = (referenceNo,zone,duration,serviceType,customerNo,serviceAmount) => {
   return Swal.fire({
        title: 'Confirm Payment',
        icon: 'warning',
        confirmButtonText: 'Pay',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        reverseButtons: true,
        customClass: {
            confirmButton: 'confirm-modal-button',
            cancelButton: 'cancel-modal-button',
            icon: 'swal-icon',
            title: 'swal-title',
            content:'swal-content',
            text:'swal-text'
        },
        html: `
            <ul class='no-bullets swal-content-container'>
                <li><span class="swal-content-title">Ref No.</span><span class="swal-content-value">${referenceNo}</span></li>
                <li><span class="swal-content-title">Zone.</span><span class="swal-content-value">${zone}</span></li>
                <li><span class="swal-content-title">Duration.</span><span class="swal-content-value">${duration}</span></li>
                <li><span class="swal-content-title">Service Type.</span><span class="swal-content-value">${serviceType}</span></li>
                <li><span class="swal-content-title">Customer's Number.</span><span class="swal-content-value">${customerNo}</span></li>
                <li><span class="swal-content-title">Amount.</span><span class="swal-content-value">${serviceAmount}</span></li>
                <li><span class="swal-content-title"></span><span class="swal-content-fee">FEE KES 0</span></li>
            </ul>
        `
    });
}

export default ConfirmPaymentAlert;