import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import DrawerAppBar from "../components/navbar";
const Services = () => {
    return(
      <Box component="main" >
         <DrawerAppBar />
      <Grid container={true} sx={{ margin: '219px 159px 146px 46px', alignContent: 'center', width: '342px', height: '235px' }} spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={12} lg={12}>
          <Typography sx={{ width: '511.7px', height: '460px', margin: ' 120px 194.3px 20px 187px', objectFit: 'contain', fontFamily: 'Inter', textAlign: 'center' }}>
            This is the services page
          </Typography>
        </Grid>
      </Grid>
    </Box>
    );
}
export default Services;