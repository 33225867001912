import { createTheme, ThemeProvider, Box, Container, Grid, Paper, Typography, Table, TableContainer, TableHead, TableRow, Button } from "@mui/material";
import DashboardNavbar from "../../components/dashboardNavbar";
import vetIcon from '../../assets/services/dashboard/vet.svg';
import marketsIcon from '../../assets/services/dashboard/markets.png';
import advertIcon from '../../assets/services/dashboard/advertising.svg';
import parkingIcon from '../../assets/services/dashboard/parking.svg';
import cessIcon from '../../assets/services/dashboard/cess.svg';
import psvIcon from '../../assets/services/dashboard/psv.png';
import licenseIcon from '../../assets/services/dashboard/licenses.svg';
import ratesIcon from '../../assets/services/dashboard/property.svg';
import sbpIcon from '../../assets/services/dashboard/sbp.svg';
import rentalsIcon from '../../assets/services/dashboard/rentals.png';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';
import { useEffect, useState } from "react";
import { Link, json } from "react-router-dom";
import { HttpRequest } from "../../components/HttpRequest";
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarExport, gridClasses } from "@mui/x-data-grid";
import { Edit, EditOutlined, Visibility } from "@mui/icons-material";


function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport sx={{ color: '#707070' }} />
        </GridToolbarContainer>
    );
}


const Businesses = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [businesses, setBusinesses] = useState([]);
    const [businessesLoading, setBusinessesLoading] = useState(true);
    const url = "get-businesses";

    const columns = [
        { field: 'businessName', headerName: 'Business Name', width: 300 },
        { field: 'businessId', headerName: 'Business ID', width: 300 },
        {
            field: 'businessNo',
            headerName: 'Business No',
            width: 300,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 300,
            valueGetter: (params) => {
                if (params.row.status === "0") {
                    return "Inactive";
                }
                return "Active"
            },
            cellClassName: (params) => {
                console.log("Status of values", params);
                if (params.row.status === "0") {
                    return "danger";
                }
                return "success"
            }

        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 300,
            renderCell: (params) => {
                return <Button variant="outlined" size="large" style={{marginRight:'50%',alignItems:'center'}}>
                    <Visibility style={{marginRight:'11%'}}/>  View 
                </Button>
            }
        }
    ];
    const requestParams = {

    }
    useEffect(() => {
        setBusinessesLoading(true);
        HttpRequest({ url, requestParams })
            .then((result) => {
                console.log('Results', result.data.status);
                if (result.data.status === "00") {
                    console.log('Results inside', result.data);
                    setBusinesses(result.data.businesses)
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setBusinessesLoading(false);
            });
    }, []);

    return (
        <Box sx={{ display: 'flex', backgroundColor: '#F6F7F8', fontFamily: 'Inter' }} >
            <DashboardNavbar />
            <Container sx={{ marginTop: '5%', marginBottom: 4, marginLeft: '-2.3%' }}>
                <Paper sx={{ width: '142%', overflow: 'hidden', marginTop: '1%', padding: '2%' }}>
                    <Typography variant="h6" sx={{
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        fontWeight: 550,
                        color: '#010202',
                        fontStretch: 'normal',
                        fontStyle: 'normal',
                        lineHeight: 2.06,
                        letterSpacing: 'normal',
                        textAlign: 'left',
                        margin: '2% 988px 25px 0'
                    }}>
                        Businesses
                    </Typography>

                    <div style={{ height: 400, width: '100%' }}>
                        <DataGrid sx={{
                            [`.${gridClasses.cell}.success`]: {
                                color: '#388E3C'
                            },
                            [`.${gridClasses.cell}.danger`]: {
                                color: '#D32F2F',
                            },
                        }}
                            rows={businesses}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                            }}
                            pageSizeOptions={[5, 10]}
                            checkboxSelection
                            slots={{ toolbar: CustomToolbar }}
                            loading={businessesLoading}
                        />
                    </div>
                </Paper>
            </Container>
        </Box >
    );
}

export default Businesses;