import { Alert, Box, Button, Grid, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import loginImage from "../../../assets/login.svg";
import googleImage from "../../../assets/google-icn.svg";
import facebookImage from "../../../assets/facebook.svg";
import phoneImage from "../../../assets/mobile.svg";
import emailImage from "../../../assets/email-icn.svg";
import twitterImage from "../../../assets/twitter-icn.svg";
import { Link } from "react-router-dom";
import DrawerAppBar from "../../../components/navbar";
import { useGoogleLogin } from "@react-oauth/google";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { googleLoginUser,loginSelector,clearState } from "../../../slices/googleLogin";
import { useDispatch, useSelector } from "react-redux";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { facebookLoginUser,facebookLoginSelector,clearState as facebookClearState } from "../../../slices/facebookLogin";

const loginOptions = [
    {
        name: 'Phone',
        image: phoneImage,
        url: 'phone'
    },
    {
        name: 'Email',
        image: emailImage,
        url: 'email'
    }
];

const facebookId =  process.env.REACT_APP_FACEBOOK_ID;
const LoginIndex = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [user, setUser] = useState([]);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState(false);
    const [facebookUser, setFacebookUser] = useState([]);

    const {isSuccess, isError, errorMessage } = useSelector(loginSelector);
    const { facebookIsSuccess, facebookIsError, facebookErrorMessage } = useSelector(facebookLoginSelector);

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });
    useEffect(() => {
        if (user) {
            if (user) {
                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            Accept: 'application/json'
                        }
                    })
                    .then((res) => {
                        console.log('Google response data', res.data);
                        const email = res.data.email;
                        const provider_id = res.data.id;
                        dispatch(googleLoginUser({email,provider_id} ))
                    })
                    .catch((err) => console.log(err));
            }
        }
    }, [user]);

    useEffect(() => {
        if (facebookUser) {
            const facebookEmail= facebookUser.email;
            const facebookId = facebookUser.id;
            facebookId && dispatch(facebookLoginUser({ facebookEmail,facebookId }))
        }
    }, [facebookUser]);

    useEffect(() => {
        return () => {
            dispatch(clearState());
        };
    }, []);
    useEffect(() => {
        return () => {
            dispatch(facebookClearState());
        };
    }, []);


    useEffect(() => {
        if (isError) {
            setOpen(true);
            setMessage(errorMessage);
            dispatch(clearState());
        }
        if (isSuccess) {
            dispatch(clearState());
            navigate('/dashboard');
        }
    }, [isError, isSuccess]);

    useEffect(() => {
        if (facebookIsError) {
            setOpen(true);
            setMessage(facebookErrorMessage);
            dispatch(facebookClearState());
        }
        if (facebookIsSuccess) {
            dispatch(facebookClearState());
            navigate('/dashboard');
        }
    }, [facebookIsError, facebookIsSuccess]);

    const handleClose = (event, reason) => {
        setOpen(false);
        setMessage("");
    };
    return (
        <>
            <DrawerAppBar />
            <Box component="main" sx={{ marginBottom: { xs: '60%', sm: '10%', md: '58%', lg: '5%' } }}>
                <Grid container={true} sx={{ marginTop: { xs: '10%', sm: '15%', md: '10%', lg: '5%' }, alignContent: 'center', width: '100%', height: '100%', textAlign: 'center' }} spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12} lg={6} sx={{ textAlign: 'center', alignContent: 'center',marginTop:{ xs: '25%', sm: '5%', md: '8%', lg: '5%' }}}>
                        <Typography sx={{ marginLeft: { xs: '10%', sm: '10%', md: '1%', lg: '18%' }, objectFit: 'contain' }}>
                            <img src={loginImage} alt="" />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} sx={{ textAlign: 'center', alignContent: 'center' }}>
                        <Box sx={{ width: '100%', textAlign: 'center' }}>
                            <Typography variant="h4" sx={{ width: '100%', height: 'auto', padding: '0 20% 0 20%', whiteSpace: "pre-wrap", marginTop: { xs: '5%', sm: '5%', md: '5%', lg: '5%' }, marginBottom: { xs: '3%', sm: '1%', md: '2%', lg: '1%' }, fontFamily: 'Inter', fontSize: '22px', fontWeight: 600, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.59, letterSpacing: 'normal', color: '#272d3b', textAlign: 'center' }}>
                                Login!
                            </Typography>
                            <Typography variant="p" sx={{ width: '100%', height: 'auto', marginTop: { xs: '5%', sm: '5%', md: '8%', lg: '28%' }, marginBottom: { xs: '5%', sm: '10%', md: '8%', lg: '90%' }, fontFamily: 'Inter', fontSize: '18px', fontWeight: 500, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.94, letterSpacing: 'normal', textAlign: 'center', color: '#707070' }}>
                                Sign in with your preferred method.
                            </Typography>
                            <Grid container={true} style={{
                                display: "flex",
                                width: "100%",
                            }}>
                                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: { xs: '8%', sm: '3%', md: '3%', lg: '3%' } }}>

                                    <Button variant="contained" onClick={() => login()} sx={{
                                        textAlign: 'start', boxShadow: 'none', border: 'solid 1px #e0e0e0', width: '40%', height: '150%', borderRadius: '4px', backgroundColor: '#fff', color: '#000', '&:hover': {
                                            backgroundColor: '#fff', color: '#000', boxShadow: 'none'
                                        }
                                    }}>
                                        <img src={googleImage} alt="" style={{ marginRight: "10%" }} />
                                        <Typography style={{ marginRight: "60%", textTransform: 'capitalize', fontWeight: 'normal', color: '#707070' }}>
                                            Google
                                        </Typography>
                                    </Button>
                                </Grid>
                                
                                <FacebookLogin
                                    appId={facebookId}
                                    onSuccess={(response) => {
                                        console.log('Login Success!', response);
                                    }}
                                    onFail={(error) => {
                                        console.log('Login Failed!', error);
                                        setMessage("Facebook login failed. Please try again.");
                                    }}
                                    onProfileSuccess={(response) => {
                                        console.log('Get Profile Success!', response);
                                        setFacebookUser(response);
                                    }}
                                    render={({ onClick, logout }) => (
                                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: { xs: '8%', sm: '5%', md: '5%', lg: '5%' } }}>

                                            <Button onClick={onClick} onLogoutClick={logout} variant="contained" sx={{
                                                textAlign: 'start', boxShadow: 'none', border: 'solid 1px #e0e0e0', width: '40%', height: '150%', borderRadius: '4px', backgroundColor: '#fff', color: '#000', '&:hover': {
                                                    backgroundColor: '#fff', color: '#000', boxShadow: 'none'
                                                }
                                            }}>
                                                <img src={facebookImage} alt="" style={{ marginRight: "10%" }} />
                                                <Typography style={{ marginRight: "60%", textTransform: 'capitalize', fontWeight: 'normal', color: '#707070' }}>
                                                    Facebook
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    )}
                                />
                                {loginOptions.map((data, index) => (

                                    <Grid key={index} item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: { xs: '8%', sm: '5%', md: '5%', lg: '5%' } }}>
                                        <Link key={index} to={data.url} >
                                            <Button variant="contained" sx={{
                                                textAlign: 'start', boxShadow: 'none', border: 'solid 1px #e0e0e0', width: '40%', height: '150%', borderRadius: '4px', backgroundColor: '#fff', color: '#000', '&:hover': {
                                                    backgroundColor: '#fff', color: '#000', boxShadow: 'none'
                                                }
                                            }}>
                                                <img src={data.image} alt="" style={{ marginRight: "10%" }} />
                                                <Typography style={{ marginRight: "60%", textTransform: 'capitalize', fontWeight: 'normal', color: '#707070' }}>
                                                    {data.name}
                                                </Typography>
                                            </Button>
                                        </Link>
                                    </Grid>
                                ))}
                            </Grid>
                            <Grid style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: { xs: '15%', sm: '10%', md: '78%', lg: '98%' },
                                marginRight: "10%",
                                marginBottom:'10%'
                            }}>
                                <Typography variant="p" style={{ marginTop: "5%", color: "#707070" }}>Don’t have an account?</Typography>
                                <Typography variant="p" style={{ marginTop: "5%", color: "#032541", marginLeft: '1%' }}><Link to="/sign-up" style={{ textDecoration: 'none', color: '#032541', fontSize: '16px', fontWeight: 'normal', fontFamily: 'Inter' }}>Sign up.</Link></Typography>
                            </Grid>
                            
                            <Snackbar open={open} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} onClose={handleClose}>
                                <Alert
                                    onClose={handleClose}
                                    severity="error"
                                    variant="filled"
                                    sx={{ width: '100%' }}
                                >
                                    {message}
                                </Alert>
                            </Snackbar>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default LoginIndex;