import { HttpRequest } from "../../../components/HttpRequest";

const MpesaRequest = async (phoneNo, amount, referenceNo,source) => {
    try {
        const requestParams = {
            "referenceNo":referenceNo,
            "amount":amount,
            "source":source,
            "moduleId":"3",
            "phoneNo":phoneNo,
        };
        const url = "pay-with-mpesa2";
        const response = await HttpRequest({url, requestParams});
        return response.data;
    } catch (error) {
        return "fail";
    }
};

export default MpesaRequest;
