import { Box, Button, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import imgHero from '../assets/hero.png';
import { Link } from "react-router-dom";

const Hero = () => {
    return (
        <Box component="main" sx={{ marginBottom: { xs: '10%', sm: '10%', md: '58%', lg: '5%' } }}>
            <Grid container={true} sx={{ marginTop: { xs: '80%', sm: '50%', md: '38%', lg: '18%' }, alignContent: 'center', width: '100%', height: '235px', textAlign: 'center' }} spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={12} md={12} lg={6} sx={{ textAlign: 'center', alignContent: 'center' }}>
                    <Typography sx={{ marginLeft: { xs: '10%', sm: '10%', md: '18%', lg: '18%' }, objectFit: 'contain' }}>
                        <img src={imgHero} alt="" style={{ width: '70%' }} />
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} sx={{ textAlign: 'center', alignContent: 'center' }}>
                    <Box sx={{ width: '100%', textAlign: 'center' }}>
                        <Typography variant="h4" sx={{ width: '75%', height: 'auto', padding: '0 20% 0 20%', whiteSpace: "pre-wrap", marginLeft: '10%', marginTop: { xs: '12%', sm: '10%', md: '5%', lg: '10%' }, marginBottom: { xs: '12%', sm: '10%', md: '8%', lg: '5%' }, fontFamily: 'Inter', fontSize: '22px', fontWeight: 600, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.59, letterSpacing: 'normal', color: '#707070', textAlign: 'center' }}>
                            Welcome to ZIZI County Government Test
                        </Typography>
                        <Typography variant="p" sx={{ width: '100%', height: 'auto', marginTop: { xs: '15%', sm: '10%', md: '8%', lg: '28%' }, marginBottom: { xs: '15%', sm: '10%', md: '8%', lg: '50%' }, fontFamily: 'Inter', fontSize: '18px', fontWeight: 500, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.94, letterSpacing: 'normal', textAlign: 'center', color: '#9b9b9b' }}>
                            What do you want to do?
                        </Typography>
                        <Grid container={true} style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            marginTop: { xs: '15%', sm: '10%', md: '78%', lg: '78%' },
                        }}>
                            <Grid item>
                                <Link to="/sign-in" >
                                    <Button variant="contained" sx={{ width: '130px', height: '50px', margin: '30% 16px 0px 0', padding: '15px 43px', borderRadius: '4px', backgroundColor: '#dc3545','&:hover':{backgroundColor: '#dc3545'} }}>Login</Button>
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link to="/sign-up">
                                    <Button variant="outlined" sx={{ width: '130px', height: '50px', margin: '30% 0 0 16px', padding: '15px 34px 15px', borderRadius: '4px', border: 'solid 1px #010202', color: '#010202','&:hover':{border:'1px solid #000',background:'#fff',textDecoration:'none'}  }}>
                                        <Typography sx={{ color: '#010202', width: '62px', height: '20px', fontFamily: 'Inter', fontSize: '16px', fontWeight: 500, fontStretch: "normal", lineHeight: 1.5, letterSpacing: 'normal', textAlign: 'center', textTransform: 'capitalize',textWrap:'nowrap',textDecoration:'none'}}>
                                            Sign-Up
                                        </Typography>
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
export default Hero;