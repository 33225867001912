import { Box, Grid, Typography } from '@mui/material';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css'
import vetIcon from '../assets/services/vet.svg';
import marketsIcon from '../assets/services/markets.svg';
import advertIcon from '../assets/services/advertising.svg';
import parkingIcon from '../assets/services/parking.svg';
import cessIcon from '../assets/services/cess.svg';
import psvIcon from '../assets/services/pas.svg';
import licenseIcon from '../assets/services/license.svg';
import ratesIcon from '../assets/services/rates.svg';
import sbpIcon from '../assets/services/SBP.svg';
import rentalsIcon from '../assets/services/rentals.svg';
import { ChevronLeft, ChevronRight} from '@mui/icons-material';
import { Link } from 'react-router-dom';


export const carouselData = [
    {
        img: vetIcon,
        title: "Vet Fees",
        link: "/vetfees",
    },
    {
        img: marketsIcon,
        title: "Markets",
        link: "/markets",
    },
    {
        img: parkingIcon,
        title: "Parking",
        link: "/parking",
    },
    {
        img: cessIcon,
        title: "Cess",
        link: "/cess",
    },
    {
        img: advertIcon,
        title: "Advertising",
        link: "/user/advertising",
    },
    {
        img: psvIcon,
        title: "PSV Stickers",
        link: "/user/psv-stickers",
    },
    {
        img: licenseIcon,
        title: "License",
        link: "/user/license",
    },
    {
        img: ratesIcon,
        title: "Land Rates",
        link: "/user/land-rates",
    },
    {
        img: sbpIcon,
        title: "SBP",
        link: "/user/sbp",
    },
    {
        img: rentalsIcon,
        title: "Rentals",
        link: "/user/rentals",
    },
];

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    return (
        <Typography >
            <ChevronLeft fontSize='large' sx={{ float: 'left', color: '#9b9b9b', marginTop: '-90px', ml: { lg: '-30px', xs: '-30px', sm: '-30px', md: '-30px' } }} className={currentSlide === 0 ? 'disable' : ''} onClick={() => previous()} />
            <ChevronRight fontSize='large' sx={{ float: 'right', color: '#9b9b9b', marginTop: '-90px', mr: { lg: '-30px', xs: '-30px', sm: '-30px', md: '-30px' } }} onClick={() => next()} />
        </Typography>
    );
};


const ServicesCarousel = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 8
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 8
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <Box sx={{ textAlign: 'center' }} component="main">
            <Grid container spacing={8} >
                <Grid item xs={12}>
                    <Typography variant='p' sx={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: 500, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 2.67, letterSpacing: 'normal', textAlign: 'center', color: '#707070' }}>
                        Select a service to make payment.
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ width: '80%', margin: '0% 10% 5% 10%' }}>
                    <Carousel arrows={false} swipeable={true} customButtonGroup={<ButtonGroup />} renderButtonGroupOutside={true}
                        responsive={responsive}
                        autoPlay={true}
                        draggable={true}
                        showDots={false}
                        infinite={true}
                        partialVisible={false}
                    >
                        {carouselData.map((data, index) => (
                            <Link key={index} to={data.link} style={{ textDecoration: 'none', color: '#707070' }} underline="none" >
                                <Grid item underline="none" sx={{ color: '#707070', textDecoration: "none" }}>
                                    <img src={data.img} alt="" style={{   width: "100%",height: "100%",maxWidth:'100px',maxHeight:'100px',objectFit: "contain",}}/>
                                    <Typography >{data.title}</Typography>
                                </Grid>
                            </Link>
                        ))}
                    </Carousel>
                </Grid>
            </Grid>
        </Box>
    );
}
export default ServicesCarousel;


