import { ThemeProvider, Paper, Container, createTheme, Box, CssBaseline, Toolbar, IconButton, Typography, Badge, Divider, Avatar, ListItemButton, ListItemIcon, ListItemText, Grid, Menu, MenuItem } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useEffect, useState } from "react";
import { AccountCircle, CalendarMonth, CardMembership, ChevronLeft, ChevronRight, Class, DirectionsCar, Home, House, PowerSettingsNew, Receipt, Search, Settings, SwapHoriz } from "@mui/icons-material";
import List from "@mui/material/List";
import imgLogo from '../assets/logo.svg';
import styled from "@emotion/styled";
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import { clearState, checkLoginStatus } from "../slices/emailLogin";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const menuItems = [
    {
        name: 'Dashboard',
        url: '/dashboard',
        icon: <Home />
    },
    {
        name: 'My Account',
        url: '/account',
        icon: <AccountCircle />
    },
    {
        name: 'Schedule My Bills',
        url: '/bills',
        icon: <CalendarMonth />
    },
    {
        name: 'Notifications',
        url: '/notifications',
        icon: <NotificationsIcon />
    },
    {
        name: 'My Invoices',
        url: '/user/invoices',
        icon: <Receipt />
    },
    {
        name: 'My Transactions',
        url: '/user/transactions',
        icon: <SwapHoriz />
    },
    {
        name: 'Settings',
        url: '/settings',
        icon: <Settings />
    }
]

const myPropertiesItems = [
    {
        name: 'Vehicles',
        url: '/user/vehicles',
        icon: <DirectionsCar />
    },
    {
        name: 'Businesses',
        url: '/user/businesses',
        icon: <Class />
    },
    {
        name: 'Houses',
        url: '/houses',
        icon: <House />
    },
    {
        name: 'Billboards',
        url: '/user/billboards',
        icon: <CardMembership />
    }
];
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    fontFamily: 'Inter',
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,

        width: `calc(98.5% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            fontFamily: 'Inter',
            backgroundColor: '#F6F7F8',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);
const defaultTheme = createTheme();

export default function DashboardNavbar() {
    const [open, setOpen] = useState(true);
    const { user } = useSelector(state => state.emailLogin);
    const dispatch = useDispatch();
    const toggleDrawer = () => {
        setOpen(!open);
    };

    useEffect(() => {
        return () => {
            dispatch(checkLoginStatus());
        };
    }, [dispatch]);

    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <AppBar position="absolute" sx={{
                boxShadow: 'none',
                backgroundColor: '#fff',
                height: '9%',
            }} open={open} color="transparent" >
                <Toolbar sx={{
                    pr: '24px',
                    pt: '2%'
                }}>
                    <IconButton edge="start" color="inherit" aria-label="open-drawer" onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' })
                        }}
                    >
                        <MenuIcon />
                        <ChevronRight
                            sx={{
                                ...(open && { display: 'none' }),
                                color: '#dc3545'
                            }}
                        />
                    </IconButton>
                    <IconButton onClick={toggleDrawer}>
                        <MenuIcon sx={{
                            ...(!open && { display: 'none' })
                        }} />
                        <ChevronLeft
                            sx={{
                                ...(!open && { display: 'none' }),
                                color: '#dc3545'
                            }}
                        />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1, fontFamily: 'Inter' }}
                    >
                        Hi, Welcome Back!
                    </Typography>
                    <IconButton sx={{ marginRight: '1%' }}>
                        <Typography sx={{ marginLeft: '5%', whiteSpace: 'nowrap' }}>Search</Typography>
                        <Search />
                    </IconButton>
                    <IconButton sx={{ marginRight: '1%' }}>
                        <CalendarMonth />
                        <Typography sx={{ marginLeft: '5%', whiteSpace: 'nowrap' }}>Schedule My Bills</Typography>
                    </IconButton>
                    <IconButton sx={{ marginRight: '1%' }}>
                        <Receipt />
                        <Typography sx={{ marginLeft: '5%', whiteSpace: 'nowrap' }}>My Invoices</Typography>
                    </IconButton>
                    <IconButton sx={{ marginRight: '1%' }}>
                        <SwapHoriz />
                        <Typography sx={{ marginLeft: '5%', whiteSpace: 'nowrap' }}>My Transactions</Typography>
                    </IconButton>
                    <IconButton>
                        <PowerSettingsNew />
                        <Typography sx={{ marginLeft: '5%' }}>Logout</Typography>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        px: [1],
                        backgroundColor: '#fff',
                    }}
                >
                    <Typography sx={{
                        ...(!open && { display: 'none' })
                    }} >
                        <img src={imgLogo} alt="" />
                    </Typography>
                    <Typography sx={{
                        fontSize: '22px',
                        color: '#dc3545',
                        fontWeight: 'normal',
                        lineHeight: 'normal',
                        textAlign: "left",
                        ...(!open && { display: 'none' })

                    }}>
                        ZIZI
                    </Typography>
                </Toolbar>

                <Grid sx={{
                    marginTop: '5%',
                    background: '#fff',
                    ...(!open && { display: 'none' })
                }}>
                    <Typography sx={{ backgroundColor: '#fff' }}>
                        <Avatar variant="rounded" sx={{ width: '70%', height: '120%', margin: '10%' }}>
                            <AccountCircle sx={{ width: '100%', height: '119px' }} />
                        </Avatar>
                    </Typography>
                    <Typography sx={{
                        textAlign: 'center',
                        color: '#144166'
                    }}>
                        {user && user.firstName ? user && user.firstName + ' ' + user.lastName : ''}
                    </Typography>
                    <Grid sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', marginBottom: '5%' }}>
                        <Typography sx={{
                            marginLeft: '20%',
                            marginRight: '1%',
                            color: '#808388'
                        }}>
                            Username:
                        </Typography>
                        <Typography sx={{
                            color: '#144166'
                        }}>
                            {user && user.username ? user.username : (user && user.idNumber ? user && user.idNumber : (user && user.idNo ? user.idNo : 'N/A'))}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider />
                <List component="nav" sx={{
                    backgroundColor: "#fff"
                }}>
                    {menuItems.map((item) => (
                        <ListItemButton>
                            <Link to={item.url} style={{ display: 'flex', textDecoration: 'none', color: '#707070' }}>
                                <ListItemIcon>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={item.name} />
                            </Link>
                        </ListItemButton>
                    ))}
                    <Divider />
                    <Typography variant="h6" sx={{ margin: '10%', fontWeight: 'bold', ...(!open && { display: 'none' }) }}>
                        My Properties
                    </Typography>
                    {myPropertiesItems.map((item) => (
                        <ListItemButton>
                            <Link to={item.url} style={{ display: 'flex', textDecoration: 'none', color: '#707070' }}>
                                <ListItemIcon>
                                    {item.icon}
                                    <Menu>
                                        <MenuItem>
                                            Heeell</MenuItem>
                                    </Menu>
                                </ListItemIcon>
                                <ListItemText primary={item.name} />
                            </Link>
                        </ListItemButton>
                    ))}
                </List>
            </Drawer>
            <Toolbar />
        </ThemeProvider>
    );


}