import DrawerAppBar from "../components/navbar";
import { Box, Grid, List, ListItem } from "@mui/material";
import Typography from "@mui/material/Typography";
import { ChevronLeft, Circle } from "@mui/icons-material";
import { Link } from "react-router-dom";

const FAQS = () => {
  return (
    <>
      <DrawerAppBar />
      <Box component="main" sx={{ marginTop: { xs: '100%', sm: '50%', md: '45%', lg: '1%' } }}>
        <Grid container={true} sx={{ alignContent: 'center', width: '100%', height: '100%', textAlign: 'center' }} spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ textAlign: 'left', alignContent: 'start', marginTop: '3.5%',marginBottom:'1%' }}>
            <Typography variant="h5" sx={{
              color: '#03541', fontFamily: 'Inter', fontSize: '27px', fontWeight: 600, textAlign: 'left', margin: '25px 942px 24px 217px', lineHeight: 1.38, letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
            }}>
              How to pay for Penalties
            </Typography>
            <Typography variant="h6" sx={{
              color: '#010202', fontFamily: 'Inter', fontSize: '18px', fontWeight: 550, textAlign: 'left', margin: '24px 859px 16px 217px', lineHeight: 1.28, letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
            }}>
              What do I do when my vehicle is clamped?
            </Typography>
            <Typography variant="body1" sx={{
              color: '#707070', fontFamily: 'Inter', fontSize: '16px', fontWeight: 'normal', textAlign: 'left', margin: '16px 159px 10px 217px', lineHeight: 'normal', letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
            }}>
              Follow the payment instructions contained in the “Parking charge Notice” placed on the car windscreen or call the phone number (for the applicable zone) for guidance.
            </Typography>
            <Typography variant="body1" sx={{
              color: '#707070', fontFamily: 'Inter', fontSize: '16px', fontWeight: 'normal', textAlign: 'left', margin: '16px 159px 10px 217px', lineHeight: 'normal', letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
            }}>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam
            </Typography>
            <Typography variant="h5" sx={{
              color: '#03541', fontFamily: 'Inter', fontSize: '27px', fontWeight: 600, textAlign: 'left', margin: '25px 942px 24px 217px', lineHeight: 1.38, letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
            }}>
              How to pay for Parking
            </Typography>
            <Typography variant="h6" sx={{
              color: '#010202', fontFamily: 'Inter', fontSize: '18px', fontWeight: 550, textAlign: 'left', margin: '24px 859px 16px 217px', lineHeight: 1.28, letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
            }}>
              Daily Street Parking
            </Typography>
            <Typography variant="h5" sx={{
              color: '#010202', fontFamily: 'Inter', fontSize: '16px', fontWeight: 550, textAlign: 'left', margin: '25px 942px 24px 217px', lineHeight: 1.38, letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
            }}>
              <Circle style={{ width: '10px', color: '#cb444a', height: '10px', mb: 10 }} /> Pay through USSD Code
            </Typography>
            <Typography variant="body1" sx={{
              color: '#707070', fontFamily: 'Inter', fontSize: '16px', fontWeight: 'normal', textAlign: 'left', margin: '16px 159px 10px 217px', lineHeight: 'normal', letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
            }}>
              Dial *007#
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet
            </Typography>
            <Typography variant="h5" sx={{
              color: '#010202', fontFamily: 'Inter', fontSize: '16px', fontWeight: 550, textAlign: 'left', margin: '25px 942px 24px 217px', lineHeight: 1.38, letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
            }}>
              <Circle style={{ width: '10px', color: '#cb444a', height: '10px', mb: 10 }} /> Pay through Web Portal
            </Typography>
            <Typography variant="body1" sx={{
              color: '#707070', fontFamily: 'Inter', fontSize: '16px', fontWeight: 'normal', textAlign: 'left', margin: '16px 159px 10px 217px', lineHeight: 'normal', letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
            }}>
              Log onto <Link to="https://zizi.co.ke/" target="_blank" style={{ textDecoration: 'underline', color: "#d33636" }} >https://zizi.co.ke/ </Link>  and select Parking services-Daily Parking
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet
            </Typography>
            <Typography variant="h6" sx={{
              color: '#010202', fontFamily: 'Inter', fontSize: '18px', fontWeight: 550, textAlign: 'left', margin: '24px 859px 16px 217px', lineHeight: 1.28, letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
            }}>
              Street Parking
            </Typography>
            <Typography variant="h5" sx={{
              color: '#010202', fontFamily: 'Inter', fontSize: '16px', fontWeight: 550, textAlign: 'left', margin: '25px 942px 24px 217px', lineHeight: 1.38, letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
            }}>
              <Circle style={{ width: '10px', color: '#cb444a', height: '10px', mb: 10 }} /> Pay through USSD Code
            </Typography>
            <Typography variant="body1" sx={{
              color: '#707070', fontFamily: 'Inter', fontSize: '16px', fontWeight: 'normal', textAlign: 'left', margin: '16px 159px 10px 217px', lineHeight: 'normal', letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
            }}>
              Dial *007#
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet
            </Typography>
            <Typography variant="h5" sx={{
              color: '#010202', fontFamily: 'Inter', fontSize: '16px', fontWeight: 550, textAlign: 'left', margin: '25px 942px 24px 217px', lineHeight: 1.38, letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
            }}>
              <Circle style={{ width: '10px', color: '#cb444a', height: '10px', mb: 10 }} /> Pay through Web Portal
            </Typography>
            <Typography variant="body1" sx={{
              color: '#707070', fontFamily: 'Inter', fontSize: '16px', fontWeight: 'normal', textAlign: 'left', margin: '16px 159px 10px 217px', lineHeight: 'normal', letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
            }}>
              Log onto  <Link to="https://zizi.co.ke/" target="_blank" style={{ textDecoration: 'underline', color: "#d33636" }} >https://zizi.co.ke/ </Link>  and select Parking services-Daily Parking
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet
            </Typography>
            <Typography variant="h6" sx={{
              color: '#010202', fontFamily: 'Inter', fontSize: '18px', fontWeight: 550, textAlign: 'left', margin: '24px 859px 16px 217px', lineHeight: 1.28, letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
            }}>
              Seasonal Parking
            </Typography>
            <Typography variant="body1" sx={{
              color: '#707070', fontFamily: 'Inter', fontSize: '16px', fontWeight: 'normal', textAlign: 'left', margin: '16px 159px 10px 217px', lineHeight: 'normal', letterSpacing: 'normal', fontStyle: 'normal', fontStretch: 'normal'
            }}>
              Log onto <Link to="https://zizi.co.ke/" target="_blank" style={{ textDecoration: 'underline', color: "#d33636" }} >https://zizi.co.ke/ </Link>  and select Parking services-Seasonal Parking
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default FAQS;