import { createAsyncThunk } from "@reduxjs/toolkit";
import { HttpRequest } from "../components/HttpRequest";
import { createSlice } from "@reduxjs/toolkit";
export const verifyPhoneNumber = createAsyncThunk(
    "verify/phone",
    async ({verificationCode}, thunkAPI) => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            console.log('This is the user',user.phone);
            const requestParams = {
                "code":verificationCode,
                "phoneNo":user.phone,
                "phoneNoChanged":"0"
            }
            const url = "verify-phone-no";
            const response = await HttpRequest({ url,requestParams });
            console.log("This is the response",response);
            let data = await response.data;
            if (data.status === "00") {
                return data;
            }else{
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error) {
            console.log("Error",error.response.data);
            thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const verifyPhoneNumberSlice = createSlice({
    name:"verifyPhoneNumber",
    initialState:{
        isFetching:false,
        isSuccess:false,
        isError:false,
        errorMessage:""
    },
    reducers:{
        clearState:(state) => {
            state.isError=false;
            state.isSuccess = false;
            state.isFetching = false;
            return state;
        },
    },
    extraReducers:(builder)=>{
        builder
        .addCase(verifyPhoneNumber.fulfilled,(state,{payload}) => {
            console.log("Payload new user",payload);
            state.isFetching=false;
            state.isSuccess=true;
            return state;
        })
        .addCase(verifyPhoneNumber.rejected,(state,{payload})=>{
            state.isFetching=false;
            state.isError=true;
            state.errorMessage=payload?.message??'Failed'
        })
        .addCase(verifyPhoneNumber.pending,(state)=>{
            state.isFetching=true;
        });
    }
});

export const {clearState} = verifyPhoneNumberSlice.actions;

export const verifyPhoneNumberSelector = (state) => state.verifyPhoneNumber;