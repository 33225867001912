import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import vetImage from '../../assets/services/vet.png';
import { ChevronLeft, Circle } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useState } from "react";
import Zones from "./components/zones";
import ServiceTypes from "./components/serviceTypes";
import FormHelperText from "@mui/material/FormHelperText";
import ProcessPayment from "./components/processPayment";
import DrawerAppBar from "../../components/navbar";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

const VetFees = () => {
    const { zones, loading: zonesLoading } = Zones({});
    const [serviceAmount, setServiceAmount] = useState(0);
    const [serviceAmountLoading, setServiceAmountLoading] = useState(true);

    const [referenceNoError, setReferenceNoError] = useState(false);
    const [zoneError, setZoneError] = useState(false);
    const [durationError, setDurationError] = useState(false);
    const [serviceTypeError, setServiceTypeError] = useState(false);
    const [paymentMethodError, setPaymentMethodError] = useState(false);
    const [customerNoError, setCustomerNoError] = useState(false);

    const [referenceNoHelperText, setReferenceNoHelperText] = useState("");
    const [zoneHelperText, setZoneHelperText] = useState("");
    const [durationHelperText, setDurationHelperText] = useState("");
    const [serviceTypeHelperText, setServiceTypeHelperText] = useState("");
    const [paymentMethodHelperText, setPaymentMethodHelperText] = useState("");
    const [customerNoHelperText, setCustomerNoHelperText] = useState("");

    const [referenceNo, setReferenceNo] = useState("");
    const [zone, setZone] = useState("");
    const [duration, setDuration] = useState("");
    const [durationId, setDurationId] = useState("");
    const [serviceType, setServiceType] = useState("");
    const serviceTypeId = 14;
    const [paymentMethod, setPaymentMethod] = useState("");
    const [customerNo, setCustomerNo] = useState("");

    const [durationLoading, setDurationLoading] = useState(true);
    const { serviceTypes, loading: serviceTypesLoading } = ServiceTypes(durationId, serviceTypeId);


    const handleSelectDuration = (event) => {
        const value = event.target.value;
        setDurationId(value);
        setDuration(value === "1" ? "Daily" : (value === "2" ? "Monthly" : "Annually"));
        setDurationLoading(false);
    }
    const handleSelectService = (event) => {
        const value = event.target.value;
        setServiceType(value);

        const servicePrice = serviceTypes.find(item => item.name === value);
        if (servicePrice) {
            setServiceAmount(servicePrice.amount);
            setServiceAmountLoading(false);
        }

    }
    const handleSubmit = (event) => {
        event.preventDefault();
        var isValid = true;
        setReferenceNoError(false);
        setReferenceNoHelperText("");

        setZoneError(false);
        setZoneHelperText("");

        setDurationError(false);
        setDurationHelperText("");

        setServiceTypeError(false);
        setServiceTypeHelperText("");

        setPaymentMethodError(false);
        setPaymentMethodHelperText("");

        setCustomerNoError(false);
        setCustomerNoHelperText("")

        if (referenceNo === '') {
            setReferenceNoError(true);
            setReferenceNoHelperText("Reference No. is required");
            isValid = false;
        }
        if (zone === '') {
            setZoneError(true);
            setZoneHelperText("Zone is required.");
            isValid = false;
        }
        if (duration === '') {
            setDurationError(true);
            setDurationHelperText("Duration is required");
            isValid = false;
        }
        if (serviceType === '') {
            setServiceTypeError(true);
            setServiceTypeHelperText("Service Type is required");
            isValid = false;
        }
        if (paymentMethod === '') {
            setPaymentMethodError(true);
            setPaymentMethodHelperText("Payment Method is required");
            isValid = false;
        } else {
            if (paymentMethod === '1' && customerNo === '') {
                setCustomerNoError(true);
                setCustomerNoHelperText("Customer's No is required.")
                isValid = false;
            }
        }
        if (isValid) {
            ProcessPayment(referenceNo, zone, duration, serviceType, customerNo, serviceAmount, "vetfees");
        }
    }
    return (
        <>
            <DrawerAppBar />
            <Box component="main" sx={{ marginBottom: { xs: '10%', sm: '10%', md: '58%', lg: '5%' }, marginTop: { xs: '100%', sm: '50%', md: '45%', lg: '15%' } }}>

                <Grid container={true} sx={{ marginTop: { xs: '100%', sm: '20%', md: '8%', lg: '12%' }, alignContent: 'center', width: '100%', height: '235px', textAlign: 'center' }} spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={12} lg={6} sx={{ textAlign: 'center', alignContent: 'center' }}>
                        <Box sx={{ width: '100%', textAlign: 'center' }}>
                            <Grid container sx={{ display: 'flex', ml: { xs: '10%', sm: '30%', md: '25%', lg: '28%' }, marginBottom: { xs: '10%', sm: '-5%', md: '-9%', lg: '2%' } }}>
                                <Typography sx={{ marginTop: { xs: '17%', sm: '8%', md: '4%', lg: '0%' } }}>
                                    <Link to='/' >
                                        <ChevronLeft style={{ fontSize: '50px', color: '#707070', fontWeight: 'normal' }} />
                                    </Link>
                                </Typography>

                                <Typography variant="h5" sx={{ marginTop: { xs: '20%', sm: '9%', md: '5%', lg: '1%' }, fontFamily: 'Inter', fontSize: '20px', fontWeight: 600, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.59, letterSpacing: 'normal', textAlign: 'left', color: '#000' }}>
                                    <Link to='/' style={{ textDecoration: 'none', color: '#000' }}>  VF- Vet Fees   </Link>
                                </Typography>
                                <Grid sx={{ display: 'flex', marginTop: { xs: '2%', sm: '1%', md: '1%', lg: '0.5%' }, marginLeft: { xs: '2%', sm: '2%', md: '2%', lg: '2%' }, width: '100%' }}>
                                    <Typography variant="p" sx={{ fontFamily: 'Inter', fontSize: '14px', fontWeight: 500, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.94, letterSpacing: 'normal', color: '#9b9b9b', textDecoration: 'underline' }}>
                                        <Link to='/' style={{ color: '#9b9b9b' }}> Home </Link>
                                    </Typography>
                                    <Typography variant="p" sx={{ paddingLeft: { xs: '2%', sm: '2%', md: '2%', lg: '1%' }, fontFamily: 'Inter', fontSize: '1px', color: '#9b9b9b', marginTop: { xs: '2%', sm: '1%', md: '1%', lg: '1%' } }}>
                                        <Circle style={{ fontSize: '10px', color: '#9b9b9b', fontWeight: 'normal' }} />
                                    </Typography>
                                    <Typography variant="p" sx={{ paddingLeft: { xs: '2%', sm: '2%', md: '2%', lg: '1%' }, fontFamily: 'Inter', fontSize: '14px', fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.94, letterSpacing: 'normal', color: '#dc3545' }}>
                                        VF- Vet Fees
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ ml: { xs: '10%', sm: '30%', md: '25%', lg: '28%' } }}>
                                <Typography variant="p" sx={{ marginLeft: '1.5%', marginTop: { xs: '0%', sm: '7%', md: '10%', lg: '0%' }, marginBottom: { xs: '3%', sm: '2%', md: '2%', lg: '2%' }, fontFamily: 'Inter', fontSize: '16px', fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 2.06, letterSpacing: 'normal', color: '#707070' }}>
                                    Fill in your Vet details.
                                </Typography>
                            </Grid>
                            <Box container={true} style={{
                                marginTop: { xs: '30%', sm: '10%', md: '58%', lg: '78%' },
                            }}>
                                <form autoComplete="off" onSubmit={handleSubmit} >
                                    <Grid container={true} sx={{ marginLeft: { xs: '10%', sm: '30%', md: '26%', lg: '29%' } }}>
                                        <FormControl sx={{ width: { xs: '75%', sm: '30%', md: '26%', lg: '30%' }, marginRight: '1%' }}>
                                            <TextField onChange={e => setReferenceNo(e.target.value)} helperText={referenceNoHelperText} value={referenceNo} placeholder="Enter Reference No." color="grey" label='Reference No.' variant="outlined" sx={{ marginRight: '1%' }} error={referenceNoError} />
                                        </FormControl>
                                        <FormControl sx={{ width: { xs: '75%', sm: '30%', md: '26%', lg: '30%' }, marginTop: { xs: '5%', sm: '0%', md: '0%', lg: '0%' } }} color="grey">
                                            <InputLabel id="zone-label" >Zone</InputLabel>
                                            <Select onChange={e => setZone(e.target.value)} SelectDisplayProps={{ style: { textAlign: 'left' } }} labelId="zone-label" id="zone-select" placeholder="Enter Reference No." color="grey" label='Zone No.' style={{ width: '100%' }} error={zoneError}   >
                                                {!zonesLoading && zones.map((data) => (
                                                    <MenuItem key={data.id} value={data.name}>{data.name}</MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText error>{zoneHelperText}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid container={true} sx={{ marginLeft: { xs: '10%', sm: '30%', md: '26%', lg: '29%' }, marginTop: { xs: '5%', sm: '2%', md: '2%', lg: '2%' } }}>
                                        <FormControl sx={{ width: { xs: '75%', sm: '30%', md: '26%', lg: '30%' }, marginRight: '1%' }} color="grey">
                                            <InputLabel id="duration-label">Duration</InputLabel>
                                            <Select onChange={handleSelectDuration} SelectDisplayProps={{ style: { textAlign: 'left' } }} labelId="duration-label" id="duration-select" placeholder="Enter Reference No." color="grey" label='Zone No.' style={{ width: '100%' }} error={durationError}  >
                                                <MenuItem value="1">Daily</MenuItem>
                                                <MenuItem value="2">Monthly</MenuItem>
                                                <MenuItem value="3">Annually</MenuItem>
                                            </Select>
                                            <FormHelperText error>{durationHelperText}</FormHelperText>
                                        </FormControl>
                                        <FormControl sx={{ width: { xs: '75%', sm: '30%', md: '26%', lg: '30%' }, marginTop: { xs: '5%', sm: '0%', md: '0%', lg: '0%' } }} color="grey">
                                            <InputLabel id="service-type-label">Service Type</InputLabel>
                                            <Select onChange={handleSelectService} SelectDisplayProps={{ style: { textAlign: 'left' } }} labelId="service-type-label" id="service-type-select" placeholder="Enter Reference No." color="grey" label='Zone No.' style={{ width: '100%' }} error={serviceTypeError}   >
                                                {!durationLoading && !serviceTypesLoading && serviceTypes.map((data) => (
                                                    <MenuItem key={data.id} value={data.name}>{data.name}</MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText error>{serviceTypeHelperText}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid container={true} sx={{ marginLeft: { xs: '10%', sm: '30%', md: '26%', lg: '29%' }, marginTop: { xs: '5%', sm: '2%', md: '2%', lg: '2%' } }}>
                                        <FormControl>
                                            <FormLabel id="demo-row-radio-buttons-group-label" sx={{mr:'35%'}}>Payment Mode</FormLabel>
                                            <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" onChange={e => setPaymentMethod(e.target.value)}>
                                                <FormControlLabel value="1" control={<Radio />} label="Mpesa" />
                                                <FormControlLabel disabled value="2" control={<Radio />} label="Card" />
                                            </RadioGroup>
                                            <FormHelperText error>{paymentMethodHelperText}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    {paymentMethod === "1" &&
                                        <Grid container={true} sx={{ marginLeft: { xs: '10%', sm: '30%', md: '26%', lg: '29%' }, marginTop: { xs: '5%', sm: '2%', md: '2%', lg: '2%' } }}>
                                            <FormControl>
                                                <PhoneInput country={'ke'} inputStyle={{ width: { xs: '75%', sm: '62%', md: '53.5%', lg: '50%' },height:'50px' }} value={customerNoHelperText} onChange={customerNo => { setCustomerNo(customerNo) }} />
                                                <FormHelperText sx={{ fontFamily: 'Inter', color: 'red', display: customerNoHelperText ? '' : 'none' }}>Please enter a valid phone number</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    }
                                    {paymentMethod === "2" &&
                                        <Box>
                                            <Grid container={true} sx={{ margin: '3% 30%' }}>
                                                <FormControl sx={{ width: '25%', marginRight: '1%' }}>
                                                    <TextField placeholder="Enter Card Number." color="grey" label="Card Number" variant="outlined" sx={{ marginRight: '1%' }} inputProps={{}} />
                                                </FormControl>
                                                <FormControl sx={{ width: '25%' }}>
                                                    <TextField placeholder="Enter Cardholder Name." color="grey" label="Cardholder Name" variant="outlined" sx={{ marginRight: '1%' }} inputProps={{}} />
                                                </FormControl>
                                            </Grid>
                                            <Grid container={true} sx={{ margin: '3% 30%' }}>
                                                <FormControl sx={{ width: '25%', marginRight: '1%' }} >
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker format="MM/YY"
                                                            views={['month', 'date']} placeholder="Enter Expiry Date." color="grey" label="Expiry Date" variant="outlined" sx={{ marginRight: '1%' }} inputProps={{}} />
                                                    </LocalizationProvider>
                                                </FormControl>
                                                <FormControl sx={{ width: '25%' }}>
                                                    <TextField placeholder="Enter Security Code." color="grey" label="Security Code" variant="outlined" sx={{ marginRight: '1%' }} inputProps={{}} />
                                                </FormControl>
                                            </Grid>
                                        </Box>

                                    }
                                    <Grid container={true} sx={{ marginLeft: { xs: '-15%', sm: '-9%', md: '-21%', lg: '-10%' }, marginTop: { xs: '10%', sm: '2%', md: '2%', lg: '2%' }, justifyContent: 'right' }}>
                                        <Button size="large" color="error" variant="outlined"> CANCEL</Button>
                                        <Button size="large" style={{ backgroundColor: referenceNo !== '' && zone !== '' && duration !== '' && serviceType !== '' && paymentMethod !== '' ? '#000' : 'rgba(3, 37, 65, 0.4)', marginLeft: '2%' }} variant="contained" type="submit"> Pay {serviceAmountLoading === true && serviceAmount === 0 ? "Now" : "KES " + serviceAmount}</Button>
                                    </Grid>
                                </form>

                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} sx={{ textAlign: 'center', alignContent: 'center' }}>
                        <Typography sx={{ marginLeft: { xs: '1%', sm: '10%', md: '18%', lg: '0%' }, objectFit: 'contain' }}>
                            <img src={vetImage} alt="" sx={{ width: { xs: '10%', sm: '50%', md: '50%', lg: '50%' } }} />
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
export default VetFees;