import { createTheme, ThemeProvider, Box, Container, Grid, Paper, Typography, Table, TableContainer, TableHead, TableRow, Button, LinearProgress } from "@mui/material";
import DashboardNavbar from "../../components/dashboardNavbar";
import { useEffect, useState } from "react";
import { Link, json } from "react-router-dom";
import { HttpRequest } from "../../components/HttpRequest";
import { DataGrid, GridLoadingOverlay, GridToolbar, GridToolbarContainer, GridToolbarExport, gridClasses } from "@mui/x-data-grid";
import { Edit, EditOutlined, Visibility } from "@mui/icons-material";


function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport sx={{ color: '#707070' }} />
        </GridToolbarContainer>
    );
}


const Vehicles = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [businesses, setBusinesses] = useState([]);
    const [businessesLoading, setBusinessesLoading] = useState(true);
    const url = "get-vehicles";

    const columns = [
        { field: 'regNo', headerName: 'Registration No.', width: 250 },
        { field: 'sacco', headerName: 'Sacco', width: 300 },
        {
            field: 'capacity',
            headerName: 'Capacity',
            width: 300,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 300,
            valueGetter: (params) => {
                if (params.row.status === "0") {
                    return "Inactive";
                }
                return "Active"
            },
            cellClassName: (params) => {
                console.log("Status of values", params);
                if (params.row.status === "0") {
                    return "danger";
                }
                return "success"
            }

        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 300,
            renderCell: (params) => {
                return <Button variant="outlined" size="large" style={{marginRight:'50%',alignItems:'center'}}>
                    <Visibility style={{marginRight:'11%'}}/>  View 
                </Button>
            }
        }
    ];
    const requestParams = {

    }
    useEffect(() => {
        setBusinessesLoading(true);
        HttpRequest({ url, requestParams })
            .then((result) => {
                console.log('Results', result.data.status);
                if (result.data.status === "00") {
                    console.log('Results inside', result.data);
                    setBusinesses(result.data.vehicles)
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setBusinessesLoading(false);
            });
    }, []);

    return (
        <Box sx={{ display: 'flex', backgroundColor: '#F6F7F8', fontFamily: 'Inter' }} >
            <DashboardNavbar />
            <Container sx={{ marginTop: '5%', marginBottom: 4, marginLeft: '-2.3%' }}>
                <Paper sx={{ width: '142%', overflow: 'hidden', marginTop: '1%', padding: '2%' }}>
                    <Typography variant="h6" sx={{
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        fontWeight: 550,
                        color: '#010202',
                        fontStretch: 'normal',
                        fontStyle: 'normal',
                        lineHeight: 2.06,
                        letterSpacing: 'normal',
                        textAlign: 'left',
                        margin: '2% 988px 25px 0'
                    }}>
                        Vehicles
                    </Typography>

                    <div style={{ height: 400, width: '100%' }}>
                     
                        <DataGrid sx={{
                            [`.${gridClasses.cell}.success`]: {
                                color: '#388E3C'
                            },
                            [`.${gridClasses.cell}.danger`]: {
                                color: '#D32F2F',
                            },
                        }}
                            rows={businesses}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                            }}
                            pageSizeOptions={[5, 10]}
                            checkboxSelection
                            slots={{ toolbar: CustomToolbar }}
                            loading={businessesLoading}
                        />
                    </div>
                </Paper>
            </Container>
        </Box >
    );
}

export default Vehicles;